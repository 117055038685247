import axios from 'axios';

// Base API URL for the live pricing data
const BASE_URL = 'https://admin.navyatechs.com/api';

// Create axios instance
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// API endpoints
export const PackagesAPI = {
  // Always use the live API endpoint
  getPackagesAllData: () => {
    return apiClient.get('/packages');
  },
};

export default apiClient; 