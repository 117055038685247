import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMessageSquare, FiX, FiChevronRight, FiSend } from 'react-icons/fi';

const ChatbotContainer = styled(motion.div)`
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  @media (max-width: 576px) {
    right: 20px;
    bottom: 20px;
  }
`;

const ChatbotButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ChatbotButton = styled(motion.button)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 24px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 14px;
    height: 14px;
    background-color: #ff4757;
    border-radius: 50%;
    border: 2px solid white;
  }
  
  &:focus {
    outline: 3px solid rgba(30, 87, 153, 0.5);
  }
`;

const FloatingLabel = styled(motion.div)`
  position: absolute;
  bottom: 100%;
  right: 50%;
  transform: translateX(50%);
  margin-bottom: 10px;
  background: #0c2340;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  pointer-events: none;
  
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    border-width: 6px;
    border-style: solid;
    border-color: #0c2340 transparent transparent transparent;
  }
`;

const ChatWindow = styled(motion.div)`
  position: absolute;
  bottom: 75px;
  right: 0;
  width: 350px;
  height: 500px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 576px) {
    width: 300px;
    height: 450px;
    right: 0;
    left: auto;
    bottom: 70px;
  }
`;

const ChatHeader = styled.div`
  padding: 15px 20px;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  h3 {
    margin: 0;
    font-size: 16px;
  }
  
  span {
    display: block;
    font-size: 12px;
    opacity: 0.8;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.15);
  }
  
  @media (max-width: 576px) {
    width: 36px;
    height: 36px;
    font-size: 22px;
  }
`;

const ChatMessages = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  /* Custom scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.isUser ? 'flex-end' : 'flex-start'};
  max-width: 80%;
  align-self: ${props => props.isUser ? 'flex-end' : 'flex-start'};
`;

const MessageBubble = styled.div`
  padding: 12px 16px;
  background: ${props => props.isUser ? 'linear-gradient(135deg, #0c2340 0%, #1e5799 100%)' : '#f0f2f5'};
  color: ${props => props.isUser ? 'white' : '#333'};
  border-radius: ${props => props.isUser ? '18px 18px 4px 18px' : '18px 18px 18px 4px'};
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
`;

const MessageTime = styled.span`
  font-size: 11px;
  color: #999;
  margin-top: 5px;
`;

const SuggestionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 5px;
  align-self: flex-start;
  width: 90%;
`;

const SuggestionButton = styled.button`
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 18px;
  padding: 10px 16px;
  text-align: left;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &:hover {
    background: #f0f7ff;
    border-color: #6978ff;
    transform: translateY(-2px);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(30, 87, 153, 0.3);
    border-color: #1e5799;
  }
  
  &:active {
    transform: translateY(0);
  }
  
  svg {
    font-size: 16px;
    color: #1e5799;
    transition: transform 0.2s ease;
  }
  
  &:hover svg {
    transform: translateX(3px);
  }
`;

const ChatInput = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #eaeaea;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  background: #f5f5f5;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  outline: none;
  transition: all 0.2s ease;
  
  &:focus {
    box-shadow: 0 0 0 2px rgba(30, 87, 153, 0.2);
    background: #fff;
  }
  
  &::placeholder {
    color: #aaa;
  }
`;

const SendButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: white;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 998;
  cursor: default;
`;

// Animation for the chatbot button
const bounceAnimation = {
  initial: { scale: 0.8, opacity: 0 },
  animate: { 
    scale: 1, 
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "spring",
      stiffness: 260,
      damping: 20
    }
  },
  bounce: {
    scale: [1, 1.1, 1],
    transition: { 
      duration: 0.6,
      times: [0, 0.5, 1],
      repeat: 3,
      repeatDelay: 10
    }
  }
};

const formatTime = () => {
  const now = new Date();
  return `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
};

const SupportChatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const messagesEndRef = useRef(null);
  const chatWindowRef = useRef(null);
  const inputRef = useRef(null);
  const buttonRef = useRef(null);

  const supportQuestions = [
    {
      id: 'q1',
      text: 'How does network monitoring work?'
    },
    {
      id: 'q2',
      text: 'What alerts can I set up?'
    },
    {
      id: 'q3',
      text: 'How secure is the platform?'
    },
    {
      id: 'q4',
      text: 'Do you offer technical support?'
    },
    {
      id: 'q5',
      text: 'What network devices do you support?'
    }
  ];

  const answers = {
    q1: "Our network monitoring solution works by collecting data from your network devices using industry-standard protocols like SNMP, NetFlow, and syslog. We analyze this data in real-time to provide insights, detect anomalies, and alert you to potential issues before they cause downtime.",
    q2: "You can set up a variety of alerts including: device status changes, bandwidth usage thresholds, CPU/memory utilization, temperature warnings, configuration changes, and custom alerts based on specific metrics or events. All alerts can be delivered via email, SMS, or through our mobile app.",
    q3: "Security is our top priority. We use end-to-end encryption for all data transmissions, implement role-based access controls, and conduct regular security audits. Our infrastructure is hosted in SOC 2 compliant data centers, and we never store your network credentials.",
    q4: "Yes! We offer 24/7 technical support through chat, email, and phone. Our enterprise plans include a dedicated support engineer who understands your network infrastructure and can provide personalized assistance.",
    q5: "We support a comprehensive range of network devices including routers, switches, firewalls, access points, servers, and IoT devices from major vendors like Cisco, Juniper, HP, Dell, Aruba, Meraki, Ubiquiti, and many more. Our platform can also monitor custom or proprietary devices using standard protocols.",
    default: "Thank you for your question. Our support team will get back to you shortly. For immediate assistance, please email us at support@navyatechs.com or call us at 1-800-NAVYA-TECH."
  };

  // Show label on first render, then every 2 minutes if chat is not open
  useEffect(() => {
    // Show on first render after 2 seconds
    const initialTimer = setTimeout(() => {
      if (!isOpen) {
        setShowLabel(true);
        // Hide after 5 seconds
        setTimeout(() => {
          setShowLabel(false);
        }, 5000);
      }
    }, 2000);

    // Show periodically if not open
    const labelInterval = setInterval(() => {
      if (!isOpen && !showLabel) {
        setShowLabel(true);
        // Hide after 5 seconds
        setTimeout(() => {
          setShowLabel(false);
        }, 5000);
      }
    }, 120000); // Every 2 minutes

    return () => {
      clearTimeout(initialTimer);
      clearInterval(labelInterval);
    };
  }, [isOpen, showLabel]);

  // Start bounce animation periodically
  useEffect(() => {
    const animationInterval = setInterval(() => {
      if (!isOpen && !isAnimating && buttonRef.current) {
        setIsAnimating(true);
        setTimeout(() => {
          setIsAnimating(false);
        }, 3000); // Animation duration plus a little buffer
      }
    }, 30000); // Every 30 seconds

    return () => clearInterval(animationInterval);
  }, [isOpen, isAnimating]);

  const toggleChat = () => {
    if (!isOpen) {
      setIsOpen(true);
      // Welcome message appears when chat is first opened
      if (messages.length === 0) {
        setMessages([
          {
            id: 1,
            text: "Hello! 👋 Welcome to Navyatechs Support. How can I help you today?",
            isUser: false,
            time: formatTime(),
            showSuggestions: true
          }
        ]);
      }
      
      // Focus the input field when chat opens
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 300);
    } else {
      setIsOpen(false);
    }
  };

  const handleSuggestionClick = (questionId) => {
    // Add user question to chat
    const question = supportQuestions.find(q => q.id === questionId);
    
    const newMessages = [
      ...messages,
      {
        id: messages.length + 1,
        text: question.text,
        isUser: true,
        time: formatTime(),
        showSuggestions: false
      }
    ];
    
    setMessages(newMessages);
    
    // After a small delay, add the answer
    setTimeout(() => {
      setMessages([
        ...newMessages,
        {
          id: newMessages.length + 1,
          text: answers[questionId] || answers.default,
          isUser: false,
          time: formatTime(),
          showSuggestions: false
        }
      ]);
    }, 500);
  };

  const handleSendMessage = () => {
    if (!inputValue.trim()) return;
    
    // Add user message
    const newMessages = [
      ...messages,
      {
        id: messages.length + 1,
        text: inputValue,
        isUser: true,
        time: formatTime(),
        showSuggestions: false
      }
    ];
    
    setMessages(newMessages);
    setInputValue('');
    
    // After a small delay, add a default response
    setTimeout(() => {
      setMessages([
        ...newMessages,
        {
          id: newMessages.length + 1,
          text: answers.default,
          isUser: false,
          time: formatTime(),
          showSuggestions: false
        }
      ]);
    }, 800);
  };

  // Scroll to bottom of messages when new ones are added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Handle Enter key in input
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <ChatbotContainer>
      <AnimatePresence>
        {isOpen && (
          <ChatWindow
            ref={chatWindowRef}
            initial={{ opacity: 0, scale: 0.8, x: 20 }}
            animate={{ opacity: 1, scale: 1, x: 0 }}
            exit={{ opacity: 0, scale: 0.8, x: 20 }}
            transition={{ duration: 0.3 }}
            role="dialog"
            aria-label="Support chat"
          >
            <ChatHeader>
              <HeaderTitle>
                <FiMessageSquare />
                <div>
                  <h3>Navyatechs Support</h3>
                  <span>Online</span>
                </div>
              </HeaderTitle>
              <CloseButton onClick={toggleChat}>
                <FiX />
              </CloseButton>
            </ChatHeader>
            
            <ChatMessages>
              {messages.map((message) => (
                <Message key={message.id} isUser={message.isUser}>
                  <MessageBubble isUser={message.isUser}>
                    {message.text}
                  </MessageBubble>
                  <MessageTime>{message.time}</MessageTime>
                  
                  {!message.isUser && message.showSuggestions && (
                    <SuggestionButtons>
                      {supportQuestions.map((question) => (
                        <SuggestionButton 
                          key={question.id} 
                          onClick={() => handleSuggestionClick(question.id)}
                        >
                          {question.text}
                          <FiChevronRight />
                        </SuggestionButton>
                      ))}
                    </SuggestionButtons>
                  )}
                </Message>
              ))}
              <div ref={messagesEndRef} />
            </ChatMessages>
            
            <ChatInput>
              <Input 
                ref={inputRef}
                type="text" 
                placeholder="Type your question here..." 
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <SendButton onClick={handleSendMessage}>
                <FiSend />
              </SendButton>
            </ChatInput>
          </ChatWindow>
        )}
      </AnimatePresence>
      
      <ChatbotButtonWrapper>
        <AnimatePresence>
          {showLabel && !isOpen && (
            <FloatingLabel
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
            >
              Need help? Chat with us!
            </FloatingLabel>
          )}
        </AnimatePresence>
        
        <ChatbotButton 
          ref={buttonRef}
          onClick={toggleChat}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          aria-label={isOpen ? "Close support chat" : "Open support chat"}
          title="Chat with support"
          variants={bounceAnimation}
          initial="initial"
          animate={isAnimating ? "bounce" : "animate"}
          onMouseEnter={() => !isOpen && setShowLabel(true)}
          onMouseLeave={() => setTimeout(() => setShowLabel(false), 300)}
        >
          {isOpen ? <FiX /> : <FiMessageSquare />}
        </ChatbotButton>
      </ChatbotButtonWrapper>
    </ChatbotContainer>
  );
};

export default SupportChatbot; 