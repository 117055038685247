import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FiDownload, FiSmartphone, FiMap, FiActivity } from 'react-icons/fi';
import Container from '../components/Container';

const Section = styled.section`
  padding: 80px 0;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
`;

const SectionBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const SectionBgPattern = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('/grid-pattern.png') repeat;
  opacity: 0.1;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 60px;
  align-items: center;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const ContentLeft = styled.div``;

const Title = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1.5rem;
  
  span {
    color: #6978ff;
  }
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled(motion.p)`
  font-size: 1.1rem;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const FeatureList = styled(motion.div)`
  margin-bottom: 2rem;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 1.5rem;
  
  @media (max-width: 992px) {
    justify-content: center;
    text-align: left;
  }
  
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const FeatureIcon = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
`;

const FeatureContent = styled.div``;

const FeatureTitle = styled.h4`
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 0.95rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
`;

const AppButton = styled(motion.a)`
  display: inline-block;
  margin-top: 1rem;
  margin-right: 1rem;
  
  img {
    height: 50px;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }
  }
`;

const ContentRight = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  
  @media (max-width: 992px) {
    margin-top: 2rem;
  }
`;

const PhoneImage = styled(motion.img)`
  max-width: 100%;
  height: auto;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transform: perspective(1000px) rotateY(-10deg) rotateX(5deg);
`;

const CircleGlow = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 73, 128, 0.3) 0%, rgba(255, 73, 128, 0) 70%);
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 }
};

const fadeInRight = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0 }
};

const fadeInLeft = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 }
};

const MobileAppSection = () => {
  const [sectionRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2
  });

  // This allows us to use imported images or fallback to public directory
  const mockupPhoneImage = process.env.PUBLIC_URL + '/images/mockupphone.png';
  const appStoreImage = process.env.PUBLIC_URL + '/images/app-store-coming-soon.png';

  return (
    <Section id="mobile-app" ref={sectionRef}>
      {/* <SectionBg>
        <SectionBgPattern />
      </SectionBg> */}
      <Container>
        <ContentWrapper>
          <ContentLeft>
            <Title 
              variants={fadeInUp}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ duration: 0.6 }}
            >
              Download our Latest Mobile app now for easy access to all our content and services!
            </Title>
            <Description
              variants={fadeInUp}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              Experience the power of our network design tools in the palm of your hand. Our mobile application offers the same powerful features as our desktop version, optimized for on-the-go access.
            </Description>
            
            <FeatureList
              variants={fadeInUp}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <FeatureItem>
                <FeatureIcon>
                  <FiMap />
                </FeatureIcon>
                <FeatureContent>
                  <FeatureTitle>Map and Diagram Editor</FeatureTitle>
                  <FeatureDescription>
                    Our application comes with a map and diagram editor that makes it easy to create and edit network diagrams and maps. You can add and remove network equipment, define connections between devices, and visualize your network in real-time.
                  </FeatureDescription>
                </FeatureContent>
              </FeatureItem>
              
              <FeatureItem>
                <FeatureIcon>
                  <FiActivity />
                </FeatureIcon>
                <FeatureContent>
                  <FeatureTitle>Powerful Network Design</FeatureTitle>
                  <FeatureDescription>
                    Our cable TV and broadband network design application is a powerful tool that allows you to create and optimize network designs quickly and easily. Whether you're designing a new network from scratch or upgrading an existing one, our application has all the features you need to ensure your network is efficient, reliable, and cost-effective.
                  </FeatureDescription>
                </FeatureContent>
              </FeatureItem>
            </FeatureList>
            
            <AppButton
              href='https://play.google.com/store/apps/details?id=com.network.navyatechs'
              target="_blank"
              variants={fadeInUp}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <img 
                alt='Get it on Google Play' 
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' 
              />
            </AppButton>
            <AppButton
              variants={fadeInUp}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <img 
                alt='Coming Soon on App Store' 
                src={appStoreImage}
                onError={(e) => {
                  e.target.src = 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83';
                  e.target.onError = null;
                }}
              />
            </AppButton>
          </ContentLeft>
          
          <ContentRight>
            <CircleGlow />
            <PhoneImage
              src={mockupPhoneImage}
              alt="Navya Techs Mobile App"
              variants={fadeInLeft}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              transition={{ duration: 0.8, delay: 0.2 }}
              onError={(e) => {
                // Fallback if the image path is incorrect - try absolute path
                e.target.src = "/Users/udayramani/Documents/Navyatechs Project/navya-techs/src/website/img/mockupphone.png";
                e.target.onError = null;
              }}
            />
          </ContentRight>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default MobileAppSection; 