import React from 'react';
import styled from 'styled-components';
import AboutUsSection from '../sections/AboutUsSection';
import CtaSection from '../sections/CtaSection';

const PageContainer = styled.div`
  padding-top: 80px;
`;

const AboutUs = () => {
  return (
    <PageContainer>
      <AboutUsSection />
      <CtaSection />
    </PageContainer>
  );
};

export default AboutUs; 