import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FiMonitor, FiAlertCircle, FiActivity, FiMap, FiTrendingUp, FiSettings } from 'react-icons/fi';
import Container from '../components/Container';

const FeaturesContainer = styled.section`
  padding: 100px 0;
  background-color: var(--color-bg-light);
  position: relative;
  overflow: hidden;
`;

const Shape = styled.div`
  position: absolute;
  border-radius: 50%;
  z-index: 0;
  
  &.shape1 {
    top: -200px;
    right: -200px;
    width: 500px;
    height: 500px;
    background: linear-gradient(135deg, rgba(93, 30, 95, 0.05) 0%, rgba(93, 30, 95, 0.02) 100%);
  }
  
  &.shape2 {
    bottom: -300px;
    left: -200px;
    width: 600px;
    height: 600px;
    background: linear-gradient(135deg, rgba(233, 30, 99, 0.05) 0%, rgba(233, 30, 99, 0.02) 100%);
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
`;

const FeaturesTitle = styled(motion.h2)`
  margin-bottom: 20px;
  
  span {
    color: var(--color-primary);
  }
`;

const FeaturesSubtitle = styled(motion.p)`
  color: var(--color-text-light);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  position: relative;
  z-index: 1;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled(motion.div)`
  background-color: white;
  border-radius: var(--border-radius);
  padding: 30px;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 0;
    background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    transition: var(--transition);
  }
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    
    &::before {
      height: 100%;
    }
    
    .feature-icon {
      background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-secondary) 100%);
      color: white;
    }
  }
`;

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: rgba(93, 30, 95, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: var(--color-primary);
  font-size: 24px;
  transition: var(--transition);
`;

const FeatureTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 15px;
`;

const FeatureDescription = styled.p`
  color: var(--color-text-light);
  line-height: 1.6;
  margin-bottom: 15px;
`;

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const features = [
  {
    id: 1,
    icon: <FiMonitor />,
    title: 'Real-Time Monitoring',
    description: 'Monitor your fiber optic network in real-time, with instant updates on performance metrics and status changes.',
  },
  {
    id: 2,
    icon: <FiAlertCircle />,
    title: 'Instant Alerts',
    description: 'Receive immediate notifications when issues arise, allowing for quick response and minimal downtime.',
  },
  {
    id: 3,
    icon: <FiActivity />,
    title: 'Performance Analytics',
    description: 'Analyze historical performance data to identify trends and potential issues before they become critical.',
  },
  {
    id: 4,
    icon: <FiMap />,
    title: 'Network Mapping',
    description: 'Visualize your entire network infrastructure with interactive maps and location-based insights.',
  },
  {
    id: 5,
    icon: <FiTrendingUp />,
    title: 'Predictive Maintenance',
    description: 'Use AI-powered predictions to schedule maintenance before equipment failures occur.',
  },
  {
    id: 6,
    icon: <FiSettings />,
    title: 'Remote Configuration',
    description: 'Configure and manage your network devices remotely, saving time and reducing operational costs.',
  },
];

const FeatureComponent = ({ feature, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <FeatureCard
      ref={ref}
      variants={fadeIn}
      initial="hidden"
      animate={controls}
      transition={{ delay: index * 0.2 }}
    >
      <IconContainer className="feature-icon">
        {feature.icon}
      </IconContainer>
      <FeatureTitle>{feature.title}</FeatureTitle>
      <FeatureDescription>{feature.description}</FeatureDescription>
    </FeatureCard>
  );
};

const FeaturesSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <FeaturesContainer id="features">
      <Shape className="shape1" />
      <Shape className="shape2" />
      <Container>
        <SectionHeader ref={ref}>
          <FeaturesTitle
            variants={fadeIn}
            initial="hidden"
            animate={controls}
          >
            Powerful <span>Features</span> for Your Network
          </FeaturesTitle>
          <FeaturesSubtitle
            variants={fadeIn}
            initial="hidden"
            animate={controls}
            transition={{ delay: 0.2 }}
          >
            Our comprehensive suite of tools helps you monitor, analyze, and optimize your fiber optic network performance.
          </FeaturesSubtitle>
        </SectionHeader>
        <FeaturesGrid>
          {features.map((feature, index) => (
            <FeatureComponent key={feature.id} feature={feature} index={index} />
          ))}
        </FeaturesGrid>
      </Container>
    </FeaturesContainer>
  );
};

export default FeaturesSection; 