import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaList, FaMapMarkedAlt, FaNetworkWired, FaToolbox, FaUsersCog } from 'react-icons/fa';
import Container from './Container';

const PageWrapper = styled.div`
  padding-top: 80px; // Account for fixed navbar
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  color: white;
  padding: 120px 0 80px;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const HeroPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  z-index: 1;
`;

const DeviceName = styled(motion.h1)`
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
`;

const DeviceFullName = styled(motion.h2)`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 30px;
  opacity: 0.9;
  position: relative;
  z-index: 2;
`;

const HeroDescription = styled(motion.p)`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
  position: relative;
  z-index: 2;
`;

const BackButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: white;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 30px;
  padding: 10px 20px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  
  svg {
    margin-right: 8px;
  }
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const DeviceImage = styled(motion.img)`
  max-width: 100%;
  max-height: 300px;
  margin: 40px auto 0;
  display: block;
  border-radius: 8px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
`;

const ContentSection = styled.section`
  padding: 80px 0;
  background: #f8fbff;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ContentBlock = styled(motion.div)`
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentHeading = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--color-text);
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 10px;
    color: var(--color-primary);
  }
`;

const ContentText = styled.p`
  font-size: 1.1rem;
  color: var(--color-text-light);
  line-height: 1.7;
  margin-bottom: 20px;
`;

const ContentList = styled.ul`
  margin: 20px 0;
  padding-left: 20px;
`;

const ContentListItem = styled.li`
  font-size: 1.1rem;
  color: var(--color-text-light);
  margin-bottom: 10px;
  list-style-type: circle;
  padding-left: 10px;
  
  &::marker {
    color: var(--color-primary);
  }
`;

const UseCaseSection = styled.section`
  padding: 80px 0;
  background: white;
`;

const UseCaseHeading = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 60px;
  text-align: center;
  color: var(--color-text);
  
  span {
    color: var(--color-primary);
  }
`;

const UseCaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const UseCaseCard = styled(motion.div)`
  background: #f8fbff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
`;

const UseCaseImage = styled.div`
  height: 200px;
  background-color: #0c2340;
  background-image: ${props => props.image ? `url(${props.image})` : 'none'};
  background-size: cover;
  background-position: center;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(12, 35, 64, 0.4), rgba(30, 87, 153, 0.4));
  }
`;

const UseCaseContent = styled.div`
  padding: 25px;
`;

const UseCaseTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--color-text);
`;

const UseCaseDescription = styled.p`
  font-size: 1rem;
  color: var(--color-text-light);
  line-height: 1.6;
`;

const NetworkDiagramSection = styled.section`
  padding: 80px 0;
  background: linear-gradient(to bottom, #f8fbff, white);
  text-align: center;
`;

const DiagramHeading = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: var(--color-text);
  
  span {
    color: var(--color-primary);
  }
`;

const DiagramDescription = styled(motion.p)`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 40px;
  color: var(--color-text-light);
  line-height: 1.6;
`;

const DiagramImage = styled(motion.img)`
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`;

const ToolsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 900px;
  margin: 60px auto 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ToolCard = styled(motion.div)`
  background: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const ToolIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--color-primary-light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  
  svg {
    font-size: 24px;
    color: white;
  }
`;

const ToolName = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--color-text);
`;

const ToolDescription = styled.p`
  font-size: 1rem;
  color: var(--color-text-light);
  line-height: 1.6;
`;

const CtaSection = styled.section`
  padding: 100px 0;
  text-align: center;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  color: white;
  position: relative;
  overflow: hidden;
`;

const CtaPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.3;
`;

const CtaHeading = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
`;

const CtaText = styled(motion.p)`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
  position: relative;
  z-index: 2;
`;

const CtaButton = styled(motion.a)`
  display: inline-block;
  padding: 15px 40px;
  background: white;
  color: var(--color-primary);
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
  }
`;

const tabVariants = {
  active: { 
    color: 'var(--color-primary)',
    borderColor: 'var(--color-primary)',
    background: 'rgba(93, 30, 95, 0.05)'
  },
  inactive: { 
    color: 'var(--color-text-light)',
    borderColor: 'transparent',
    background: 'transparent' 
  }
};

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0, 
    transition: { duration: 0.6, ease: "easeOut" } 
  }
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const DeviceDetailTemplate = ({ 
  deviceName, 
  fullName, 
  description,
  image,
  specifications,
  features,
  useCases,
  networkDiagram,
  compatibleDevices,
  designTools
}) => {
  return (
    <PageWrapper>
      <HeroSection>
        <HeroPattern />
        <Container>
          <BackButton to="/">
            <FaArrowLeft /> Back to Home
          </BackButton>
          <DeviceName
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {deviceName}
          </DeviceName>
          <DeviceFullName
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            {fullName}
          </DeviceFullName>
          <HeroDescription
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            {description}
          </HeroDescription>
          <DeviceImage 
            src={image}
            alt={deviceName}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          />
        </Container>
      </HeroSection>

      {/* <ContentSection>
        <Container>
          <ContentGrid>
            <ContentBlock
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true, margin: "-100px" }}
            >
              <ContentHeading>
                <FaList /> Specifications
              </ContentHeading>
              <ContentText>
                {specifications.intro}
              </ContentText>
              <ContentList>
                {specifications.items.map((item, index) => (
                  <ContentListItem key={index}>{item}</ContentListItem>
                ))}
              </ContentList>
            </ContentBlock>

            <ContentBlock
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true, margin: "-100px" }}
            >
              <ContentHeading>
                <FaToolbox /> Features & Benefits
              </ContentHeading>
              <ContentText>
                {features.intro}
              </ContentText>
              <ContentList>
                {features.items.map((item, index) => (
                  <ContentListItem key={index}>{item}</ContentListItem>
                ))}
              </ContentList>
            </ContentBlock>
          </ContentGrid>
        </Container>
      </ContentSection> */}

      <UseCaseSection>
        <Container>
          <UseCaseHeading
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true, margin: "-100px" }}
          >
            <span>Use Cases</span> & Applications
          </UseCaseHeading>
          <UseCaseGrid>
            {useCases.map((useCase, index) => (
              <UseCaseCard 
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true, margin: "-100px" }}
              >
                <UseCaseImage image={useCase.image} />
                <UseCaseContent>
                  <UseCaseTitle>{useCase.title}</UseCaseTitle>
                  <UseCaseDescription>{useCase.description}</UseCaseDescription>
                </UseCaseContent>
              </UseCaseCard>
            ))}
          </UseCaseGrid>
        </Container>
      </UseCaseSection>

      <NetworkDiagramSection>
        <Container>
          {/* <DiagramHeading
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true, margin: "-100px" }}
          >
            Network <span>Diagram</span>
          </DiagramHeading>
          <DiagramDescription
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            viewport={{ once: true, margin: "-100px" }}
          >
            {networkDiagram.description}
          </DiagramDescription>
          <DiagramImage 
            src={networkDiagram.image} 
            alt="Network Diagram"
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true, margin: "-100px" }}
          /> */}
          
          {/* <ContentHeading style={{ justifyContent: 'center', marginTop: '60px', marginBottom: '30px' }}>
            <FaNetworkWired /> Compatible Devices
          </ContentHeading>
          <ContentText style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto 30px' }}>
            {compatibleDevices.intro}
          </ContentText> */}
          {/* <ContentList style={{ maxWidth: '600px', margin: '0 auto', columns: 2 }}>
            {compatibleDevices.items.map((item, index) => (
              <ContentListItem key={index}>{item}</ContentListItem>
            ))}
          </ContentList> */}
          
          <ContentHeading style={{ justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
            <FaMapMarkedAlt /> Design & Planning Tools
          </ContentHeading>
          <ContentText style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto 40px' }}>
            Design your FTTH network with our professional tools and create detailed network maps.
          </ContentText>
          
          <ToolsGrid>
            {designTools.map((tool, index) => (
              <ToolCard
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true, margin: "-100px" }}
              >
                <ToolIcon>
                  {tool.icon}
                </ToolIcon>
                <ToolName>{tool.name}</ToolName>
                <ToolDescription>{tool.description}</ToolDescription>
              </ToolCard>
            ))}
          </ToolsGrid>
        </Container>
      </NetworkDiagramSection>

      <CtaSection>
        <CtaPattern />
        <Container>
          <CtaHeading
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true, margin: "-100px" }}
          >
            Ready to Design Your FTTH Network?
          </CtaHeading>
          <CtaText
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            viewport={{ once: true, margin: "-100px" }}
          >
            Partner with Navyatechs for expert network design services. Our team will help you create optimized, efficient FTTH network architectures tailored to your specific requirements and budget.
          </CtaText>
          <CtaButton 
            href="https://app.navyatechs.com/register"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true, margin: "-100px" }}
          >
            Request Network Design
          </CtaButton>
        </Container>
      </CtaSection>
    </PageWrapper>
  );
};

export default DeviceDetailTemplate; 