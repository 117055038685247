import React from 'react';
import styled from 'styled-components';
import Container from '../components/Container';

const PageContainer = styled.div`
  padding: 150px 0 100px;
  background-color: var(--color-light);
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const PageTitle = styled.h1`
  font-size: 2.5rem;
  color: var(--color-secondary-dark);
  margin-bottom: 15px;
`;

const PageSubtitle = styled.p`
  font-size: 1.1rem;
  color: var(--color-text);
  max-width: 700px;
  margin: 0 auto 30px;
  line-height: 1.6;
`;

const UpdatedDate = styled.div`
  font-size: 0.9rem;
  color: var(--color-text-light);
`;

const ContentWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 40px;
  
  @media (max-width: 576px) {
    padding: 30px 20px;
  }
`;

const Section = styled.section`
  margin-bottom: 40px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: var(--color-secondary-dark);
`;

const SectionContent = styled.div`
  color: var(--color-text);
  line-height: 1.7;
  
  p {
    margin-bottom: 15px;
  }
  
  ul {
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  li {
    margin-bottom: 10px;
  }
`;

const Terms = () => {
  return (
    <PageContainer>
      <Container>
        <PageHeader>
          <PageTitle>Terms of Service</PageTitle>
          <PageSubtitle>
            Please read these Terms of Service carefully before using the Navya Techs network monitoring platform.
          </PageSubtitle>
          <UpdatedDate>Last Updated: July 1, 2023</UpdatedDate>
        </PageHeader>
        
        <ContentWrapper>
          <Section>
            <SectionTitle>1. Acceptance of Terms</SectionTitle>
            <SectionContent>
              <p>
                By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>2. Description of Service</SectionTitle>
            <SectionContent>
              <p>
                Navya Techs provides a network monitoring platform designed to help businesses monitor, manage, and optimize their fiber optic networks. Our service includes real-time monitoring, alerts, performance analytics, network mapping, and remote configuration capabilities.
              </p>
              <p>
                We reserve the right to modify, suspend or discontinue, temporarily or permanently, the Service or any service to which it connects, with or without notice and without liability to you.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>3. Subscriptions</SectionTitle>
            <SectionContent>
              <p>
                Some parts of the Service are billed on a subscription basis. You will be billed in advance on a recurring and periodic basis (such as daily, monthly or annually), depending on the type of subscription plan you select when purchasing the Subscription.
              </p>
              <p>
                At the end of each period, your subscription will automatically renew under the exact same conditions unless you cancel it or Navya Techs cancels it.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>4. Free Trial</SectionTitle>
            <SectionContent>
              <p>
                Navya Techs may, at its sole discretion, offer a Subscription with a free trial for a limited period of time.
              </p>
              <p>
                You may be required to enter your billing information in order to sign up for the Free Trial.
              </p>
              <p>
                If you do enter your billing information when signing up for a Free Trial, you will not be charged by Navya Techs until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable subscription fee for the type of Subscription you have selected.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>5. Account Registration</SectionTitle>
            <SectionContent>
              <p>
                You may be required to register with Navya Techs in order to access and use the Service. You agree to keep your password confidential and will be responsible for all use of your account and password.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>6. Intellectual Property</SectionTitle>
            <SectionContent>
              <p>
                The Service and its original content, features and functionality are and will remain the exclusive property of Navya Techs and its licensors. The Service is protected by copyright, trademark, and other laws of both India and foreign countries.
              </p>
              <p>
                Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Navya Techs.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>7. Termination</SectionTitle>
            <SectionContent>
              <p>
                We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
              </p>
              <p>
                Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>8. Limitation of Liability</SectionTitle>
            <SectionContent>
              <p>
                In no event shall Navya Techs, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>9. Governing Law</SectionTitle>
            <SectionContent>
              <p>
                These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.
              </p>
              <p>
                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>10. Changes to Terms</SectionTitle>
            <SectionContent>
              <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect.
              </p>
              <p>
                By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>11. Contact Us</SectionTitle>
            <SectionContent>
              <p>
                If you have any questions about these Terms, please contact us:
              </p>
              <ul>
                <li>By email: info@navyatechs.com</li>
                <li>By phone: +91 95740 01157 / +91 78619 86275</li>
                <li>By mail: 126-Navya Techs, Time Square Empire, Mirzapar Bypass Rd, Bhuj-370001</li>
              </ul>
            </SectionContent>
          </Section>
        </ContentWrapper>
      </Container>
    </PageContainer>
  );
};

export default Terms; 