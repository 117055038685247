import React from 'react';
import styled from 'styled-components';
import CtaSection from '../sections/CtaSection';
import Footer from './Footer';

const WrapperContainer = styled.div`
  position: relative;
  // margin-top: 10px;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  margin-top: -1px;
  z-index: 1;
`;

const ShapePattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/grid-pattern.png');
  opacity: 0.1;
  z-index: 1;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 25% 25%, rgba(105, 120, 255, 0.3) 0%, transparent 50%),
              radial-gradient(circle at 75% 75%, rgba(105, 120, 255, 0.3) 0%, transparent 50%);
  z-index: 2;
`;

const FooterWrapper = () => {
  return (
    <WrapperContainer>
      {/* <ShapePattern />
      <GradientOverlay /> */}
      <CtaSection />
      <Footer />
    </WrapperContainer>
  );
};

export default FooterWrapper; 