import React from 'react';
import { FaMapMarkedAlt, FaNetworkWired, FaSitemap, FaProjectDiagram, FaRuler, FaTools } from 'react-icons/fa';
import DeviceDetailTemplate from '../../components/DeviceDetailTemplate';

const TransmitterPage = () => {
  const deviceData = {
    deviceName: "Transmitter",
    fullName: "Optical Transmitter Network Design Services",
    description: "Navyatechs specializes in designing high-performance FTTH networks incorporating optical transmitter technology. Our expert network designs optimize the conversion of electrical signals to optical signals with exceptional reliability for comprehensive network coverage.",
    image: "/assets/devices/transimg.png",
    
    specifications: {
      intro: "Our network designs incorporate cutting-edge optical transmitter technology with these specifications:",
      items: [
        "Output power planning from 4dBm to 18dBm",
        "Wavelength strategy options: 1310nm, 1550nm, and CWDM wavelengths",
        "Modulation format selection: Direct and external modulation",
        "Input signal compatibility planning: NTSC, PAL, DVB-C, and ISDB-T formats",
        "RF input level optimization: 75-85 dBμV",
        "Environmental planning for -5°C to +55°C operation",
        "Power consumption efficiency optimization",
        "Comprehensive monitoring and management system design",
        "Equipment housing design for rack-mountable configurations",
        "Management system integration: SNMP, telnet, and web-based interfaces"
      ]
    },
    
    features: {
      intro: "Our transmitter-based network designs offer key advantages for operators building robust FTTH infrastructures:",
      items: [
        "High output power planning for extensive network coverage",
        "Low noise figure strategy ensuring clean signal transmission",
        "Automatic gain control implementation for consistent performance",
        "Advanced cooling system design preventing thermal issues",
        "Real-time monitoring system architecture",
        "Power redundancy planning for high availability",
        "Comprehensive alarm system design with notification options",
        "Integration strategy with other network components",
        "Installation and maintenance planning for simplified operations",
        "Scalable architecture design supporting network growth"
      ]
    },
    
    useCases: [
      {
        title: "Metropolitan Network Design",
        description: "We design optimized networks for dense urban environments with strategic transmitter placement to provide high-bandwidth services to multiple buildings with minimal signal degradation.",
        image: "/assets/usecases/metropolitan.jpg"
      },
      {
        title: "Rural Broadband Planning",
        description: "Our team creates network designs that extend fiber connectivity to underserved areas with high-power transmitter strategies that can cover longer distances while maintaining signal quality.",
        image: "/assets/usecases/rural.jpg"
      },
      {
        title: "Business Service Architecture",
        description: "We develop specialized network designs for enterprise-grade connectivity with guaranteed bandwidth and reliability for corporate clients requiring dedicated fiber connections.",
        image: "/assets/usecases/business.jpg"
      }
    ],
    
    networkDiagram: {
      description: "Our expert team designs comprehensive FTTH networks using transmitters as the central signal source. We create optimized architectures connecting to multiple EDFAs, splitters, and ONTs to create an efficient, reliable network design tailored to your specific requirements.",
      image: "/assets/diagrams/transmitter-network.jpg"
    },
    
    compatibleDevices: {
      intro: "Our network designs incorporate a wide range of compatible components to create cohesive transmitter-based solutions:",
      items: [
        "EDFA Amplifiers",
        "PON EDFA Systems",
        "Optical Splitters",
        "GPON OLT/ONT",
        "EPON Systems",
        "Optical Receivers",
        "WDM Multiplexers",
        "Optical Couplers",
        "PLC Splitters",
        "Fiber Patch Panels",
        "Optical Attenuators",
        "Fiber Distribution Hubs"
      ]
    },
    
    designTools: [
      {
        name: "Network Mapping",
        description: "Our team creates detailed visual representations of your fiber network with intuitive mapping tools, including optimized transmitter placement strategies.",
        icon: <FaMapMarkedAlt />
      },
      {
        name: "Link Budget Calculator",
        description: "We calculate precise optical power budgets across your network to ensure optimal signal strength at all endpoints and identify potential issues before deployment.",
        icon: <FaRuler />
      },
      {
        name: "Component Placement",
        description: "Our experts optimize the placement of transmitters, splitters, and other network elements in your design for maximum coverage and efficiency.",
        icon: <FaSitemap />
      },
      {
        name: "Network Simulation",
        description: "We simulate network performance under various conditions to identify and address potential bottlenecks before physical deployment.",
        icon: <FaNetworkWired />
      },
      {
        name: "Custom Topology Design",
        description: "Our team designs custom network topologies specific to your geographical and technical requirements with comprehensive planning tools.",
        icon: <FaProjectDiagram />
      },
      {
        name: "Installation Guide",
        description: "We provide step-by-step installation and configuration guides for optimal transmitter setup and integration with your network design.",
        icon: <FaTools />
      }
    ]
  };

  return <DeviceDetailTemplate {...deviceData} />;
};

export default TransmitterPage; 