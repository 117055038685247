import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --color-primary: #5D1E5F;
    --color-primary-light: #874f89;
    --color-primary-dark: #451646;
    --color-secondary: #E91E63;
    --color-secondary-light: #ee4c83;
    --color-secondary-dark: #b5124c;
    --color-accent: #4361ee;
    --color-accent-light: #6c82f2;
    --color-accent-dark: #2945cb;
    --color-text: #1A1A2E;
    --color-text-light: #4e4e68;
    --color-light: #F8F9FA;
    --color-dark: #0b1327;
    --box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --color-bg: #ffffff;
    --color-bg-light: #f8f9fa;
    --color-bg-dark: #e9ecef;
    --color-success: #28a745;
    --color-info: #17a2b8;
    --color-warning: #ffc107;
    --color-danger: #dc3545;
    --transition: all 0.3s ease;
    --border-radius: 8px;
    --font-primary: 'Nunito', sans-serif;
    --font-secondary: 'Poppins', sans-serif;
    --max-width: 1200px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    font-family: var(--font-family);
    font-size: 16px;
    scroll-behavior: smooth;
    color: var(--color-text);
    background-color: #fff;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.3;
  }

  h1 {
    font-size: 3.5rem;
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: 2.5rem;
    
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 2rem;
    
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    line-height: 1.7;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  button, input, textarea, select {
    font-family: inherit;
    font-size: 1rem;
    outline: none;
    border: none;
  }

  button {
    cursor: pointer;
    background: none;
  }

  section {
    padding: 5rem 0;
  }

  .container {
    width: 90%;
    max-width: var(--max-width);
    margin: 0 auto;
  }

  .text-center {
    text-align: center;
  }

  .text-gradient {
    background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .mb-1 {
    margin-bottom: 0.5rem;
  }

  .mb-2 {
    margin-bottom: 1rem;
  }

  .mb-3 {
    margin-bottom: 1.5rem;
  }

  .mb-4 {
    margin-bottom: 2rem;
  }

  .mb-5 {
    margin-bottom: 3rem;
  }

  .hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(50px);
    transition: all 1s;
  }

  .show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }

  @media (prefers-reduced-motion) {
    .hidden {
      transition: none;
    }
  }
`;

export default GlobalStyles; 