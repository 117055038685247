import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FiChevronLeft, FiChevronRight, FiStar } from 'react-icons/fi';
import Container from '../components/Container';

const TestimonialsContainer = styled.section`
  padding: 120px 0;
  background-color: var(--color-light);
  position: relative;
  overflow: hidden;
`;

const Shape = styled.div`
  position: absolute;
  border-radius: 50%;
  
  &:first-child {
    width: 300px;
    height: 300px;
    background: radial-gradient(circle at center, rgba(93, 30, 95, 0.05) 0%, transparent 70%);
    top: -50px;
    left: -100px;
  }
  
  &:last-child {
    width: 500px;
    height: 500px;
    background: radial-gradient(circle at center, rgba(93, 30, 95, 0.05) 0%, transparent 70%);
    bottom: -200px;
    right: -200px;
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto 80px;
  position: relative;
  z-index: 1;
`;

const TestimonialsTitle = styled(motion.h2)`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--color-secondary-dark);
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const TestimonialsSubtitle = styled(motion.p)`
  font-size: 1.1rem;
  color: var(--color-text);
  line-height: 1.6;
`;

const TestimonialsWrapper = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
`;

const TestimonialsTrack = styled(motion.div)`
  display: flex;
  overflow: hidden;
`;

const TestimonialSlide = styled(motion.div)`
  min-width: 100%;
  padding: 0 15px;
`;

const TestimonialCard = styled(motion.div)`
  background: white;
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  
  &::before {
    content: '"';
    position: absolute;
    top: 25px;
    left: 30px;
    font-size: 120px;
    color: rgba(93, 30, 95, 0.07);
    font-family: Georgia, serif;
    line-height: 0.8;
  }
`;

const TestimonialContent = styled.div`
  flex: 1;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
`;

const TestimonialText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--color-text);
  font-style: italic;
`;

const RatingStars = styled.div`
  display: flex;
  margin: 20px 0;
  color: #FFBB00;
`;

const Star = styled(FiStar)`
  stroke-width: 1;
  fill: #FFBB00;
  margin-right: 5px;
`;

const TestimonialAuthor = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  flex-shrink: 0;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AuthorInfo = styled.div``;

const AuthorName = styled.h4`
  margin: 0 0 5px;
  font-size: 1.1rem;
  color: var(--color-secondary-dark);
`;

const AuthorTitle = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: var(--color-text-light);
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 15px;
`;

const NavButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  border: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-secondary);
  cursor: pointer;
  transition: var(--transition);
  
  &:hover {
    background-color: var(--color-primary);
    color: white;
    transform: translateY(-3px);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }
`;

const TestimonialIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 10px;
`;

const Indicator = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? 'var(--color-primary)' : 'rgba(93, 30, 95, 0.2)'};
  border: none;
  padding: 0;
  cursor: pointer;
  transition: var(--transition);
  
  &:hover {
    background-color: ${props => props.active ? 'var(--color-primary)' : 'rgba(93, 30, 95, 0.4)'};
  }
`;

// Example testimonials data
const testimonials = [
  {
    id: 1,
    text: "Navya Techs' network monitoring solution has completely transformed how we manage our fiber optic infrastructure. The real-time alerts and detailed analytics have helped us reduce downtime by 35% in just three months.",
    rating: 5,
    author: {
      name: "Rajesh Kumar",
      title: "Network Operations Director, TechCorp India",
      avatar: "https://randomuser.me/api/portraits/men/32.jpg"
    }
  },
  {
    id: 2,
    text: "As an ISP serving over 50,000 customers, network reliability is our top priority. Navya Techs' solution gives us unprecedented visibility into our fiber network and has been instrumental in improving our customer satisfaction scores.",
    rating: 5,
    author: {
      name: "Priya Sharma",
      title: "CTO, SpeedNet Communications",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg"
    }
  },
  {
    id: 3,
    text: "The predictive maintenance feature is a game-changer. We've been able to address potential issues before they impact our services. Implementation was smooth and the support team has been exceptional throughout our journey.",
    rating: 5,
    author: {
      name: "Amit Patel",
      title: "Infrastructure Manager, DataSphere",
      avatar: "https://randomuser.me/api/portraits/men/67.jpg"
    }
  },
  {
    id: 4,
    text: "Our data center's uptime has improved dramatically since implementing Navya Techs' monitoring solution. The ROI has been clear - fewer outages, faster problem resolution, and better overall network performance.",
    rating: 5,
    author: {
      name: "Sneha Verma",
      title: "Operations Head, CloudEdge Data Centers",
      avatar: "https://randomuser.me/api/portraits/women/28.jpg"
    }
  }
];

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const trackRef = useRef(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const goToNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setDirection(index > currentIndex ? 1 : -1);
    setCurrentIndex(index);
  };

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0
    }),
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0
    })
  };

  // Auto-play functionality
  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 6000);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <TestimonialsContainer id="testimonials" ref={ref}>
      <Shape />
      <Shape />
      <Container>
        <SectionHeader>
          <TestimonialsTitle
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeInUp}
          >
            What Our Clients Say
          </TestimonialsTitle>
          <TestimonialsSubtitle
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeInUp}
          >
            Don't just take our word for it. See how our network monitoring solution has transformed 
            businesses across various industries.
          </TestimonialsSubtitle>
        </SectionHeader>
        
        <TestimonialsWrapper ref={trackRef}>
          <TestimonialsTrack>
            <motion.div
              key={currentIndex}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ type: 'tween', duration: 0.5 }}
              style={{ width: '100%' }}
            >
              <TestimonialSlide>
                <TestimonialCard>
                  <TestimonialContent>
                    <TestimonialText>
                      {testimonials[currentIndex].text}
                    </TestimonialText>
                    <RatingStars>
                      {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                        <Star key={i} />
                      ))}
                    </RatingStars>
                  </TestimonialContent>
                  <TestimonialAuthor>
                    <AuthorAvatar>
                      <img 
                        src={testimonials[currentIndex].author.avatar} 
                        alt={testimonials[currentIndex].author.name} 
                      />
                    </AuthorAvatar>
                    <AuthorInfo>
                      <AuthorName>{testimonials[currentIndex].author.name}</AuthorName>
                      <AuthorTitle>{testimonials[currentIndex].author.title}</AuthorTitle>
                    </AuthorInfo>
                  </TestimonialAuthor>
                </TestimonialCard>
              </TestimonialSlide>
            </motion.div>
          </TestimonialsTrack>
          
          <NavigationButtons>
            <NavButton onClick={goToPrev}>
              <FiChevronLeft size={24} />
            </NavButton>
            <NavButton onClick={goToNext}>
              <FiChevronRight size={24} />
            </NavButton>
          </NavigationButtons>
          
          <TestimonialIndicators>
            {testimonials.map((_, index) => (
              <Indicator 
                key={index} 
                active={index === currentIndex} 
                onClick={() => goToSlide(index)}
              />
            ))}
          </TestimonialIndicators>
        </TestimonialsWrapper>
      </Container>
    </TestimonialsContainer>
  );
};

export default TestimonialsSection; 