import React from 'react';
import { FaMapMarkedAlt, FaNetworkWired, FaSitemap, FaProjectDiagram, FaRuler, FaTools } from 'react-icons/fa';
import DeviceDetailTemplate from '../../components/DeviceDetailTemplate';

const EdfaPage = () => {
  const deviceData = {
    deviceName: "EDFA",
    fullName: "EDFA Network Design Services",
    description: "Navyatechs specializes in designing FTTH networks optimized with Erbium Doped Fiber Amplifier (EDFA) technology. Our expert designs incorporate advanced optical amplification strategies to extend network reach while maintaining signal integrity across long distances.",
    image: "/assets/devices/edfaimg.png",
    
    specifications: {
      intro: "Our network designs incorporate state-of-the-art EDFA technology specifications for exceptional performance:",
      items: [
        "Output power planning from 14dBm to 30dBm",
        "Wavelength optimization for 1540nm to 1565nm (C-band)",
        "Gain flatness design of ≤ ±0.5dB",
        "Noise figure planning of ≤ 5.5dB typical",
        "Gain control strategy: Automatic or manual options",
        "Polarization mode dispersion design of ≤ 0.5ps",
        "Environmental planning for -5°C to +65°C operation",
        "WDM integration for management channel",
        "Monitoring system design via SNMP, web interface",
        "Redundant power supply planning",
        "Equipment housing design for standard 19-inch racks"
      ]
    },
    
    features: {
      intro: "Our EDFA network design services provide critical advantages for expanding and optimizing your FTTH network:",
      items: [
        "High gain amplification planning across the entire C-band",
        "Low noise figure strategy for minimal signal degradation",
        "Gain flattening filter implementation for consistent amplification",
        "Automatic gain control planning for stable output",
        "Comprehensive status monitoring system design",
        "Alarm system architecture with remote notification",
        "Equipment redundancy planning for minimal downtime",
        "Cascaded amplifier configuration strategy",
        "Energy-efficient operation planning to reduce costs",
        "Modular design approach allowing for future upgrades",
        "Network management system integration planning"
      ]
    },
    
    useCases: [
      {
        title: "Long-Distance Network Design",
        description: "We create network designs with strategic EDFA placement to extend coverage over long distances without compromising signal quality or requiring regeneration.",
        image: "/assets/usecases/longdistance.jpg"
      },
      {
        title: "Dense Urban Network Planning",
        description: "Our metropolitan network designs incorporate EDFA technology to distribute high-quality signals to multiple buildings and neighborhoods from a central source.",
        image: "/assets/usecases/urban.jpg"
      },
      {
        title: "Multi-Level Splitting Architecture",
        description: "We develop network designs that enable deeper splitting ratios while maintaining signal integrity, allowing more end-users to be served from a single fiber line.",
        image: "/assets/usecases/splitting.jpg"
      }
    ],
    
    networkDiagram: {
      description: "Our expert team creates sophisticated FTTH network designs with strategic EDFA placement as signal boosters between transmitters and end-users. We develop efficient network architectures with optimal amplification points to extend reach and serve more customers.",
      image: "/assets/diagrams/edfa-network.jpg"
    },
    
    compatibleDevices: {
      intro: "Our network designs incorporate a wide range of compatible components to create cohesive EDFA-enhanced solutions:",
      items: [
        "Optical Transmitters",
        "PON-EDFA Systems",
        "GPON OLT/ONT Equipment",
        "EPON Systems",
        "WDM Multiplexers",
        "Optical Splitters",
        "PLC Splitters",
        "Optical Receivers",
        "Fiber Distribution Panels",
        "Optical Couplers",
        "CWDM/DWDM Systems",
        "Optical Circulators"
      ]
    },
    
    designTools: [
      {
        name: "Amplifier Placement",
        description: "Our expert team optimizes the placement of EDFAs in your network design for maximum coverage and efficiency using our interactive planning tools.",
        icon: <FaSitemap />
      },
      {
        name: "Power Budget Analysis",
        description: "We calculate detailed optical power budgets across your network considering EDFA gain, fiber loss, and splitting ratios to ensure reliable connectivity.",
        icon: <FaRuler />
      },
      {
        name: "Network Topology Design",
        description: "Our team creates custom network topologies with precise EDFA placement to maximize network efficiency and coverage for your specific requirements.",
        icon: <FaProjectDiagram />
      },
      {
        name: "Signal Quality Mapping",
        description: "We generate visual maps of expected signal quality throughout your network to identify and address potential problem areas before deployment.",
        icon: <FaMapMarkedAlt />
      },
      {
        name: "Network Performance Simulation",
        description: "Our experts simulate network performance with different EDFA configurations to determine the optimal setup for your specific needs.",
        icon: <FaNetworkWired />
      },
      {
        name: "Configuration Assistant",
        description: "We provide step-by-step guidance for optimal EDFA configuration and integration with your existing network infrastructure.",
        icon: <FaTools />
      }
    ]
  };

  return <DeviceDetailTemplate {...deviceData} />;
};

export default EdfaPage; 