import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FiTarget, FiCompass, FiAward, FiCheckCircle, FiTrendingUp, FiUsers } from 'react-icons/fi';
import Container from '../components/Container';

const PageContainer = styled.div`
  padding-top: 80px;
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  padding: 100px 0;
  position: relative;
  overflow: hidden;
`;

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const HeroBgPattern = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('/grid-pattern.png') repeat;
  opacity: 0.1;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
`;

const PageTitle = styled(motion.h1)`
  font-size: 3.5rem;
  font-weight: 800;
  color: white;
  margin-bottom: 1.5rem;
  
  span {
    color: var(--color-accent-dark);
  }
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Tagline = styled(motion.h2)`
  font-size: 1.8rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
  
  span {
    color: #6978ff;
  }
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled(motion.p)`
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Section = styled.section`
  padding: 100px 0;
  background-color: ${props => props.bg || 'white'};
`;

const SectionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-text);
  text-align: center;
  margin-bottom: 2rem;
  
  span {
    color: var(--color-primary);
  }
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  line-height: 1.8;
  color: var(--color-text-light);
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 3rem;
  }
`;

const VisionMissionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VisionMissionCard = styled(motion.div)`
  background-color: white;
  border-radius: 16px;
  padding: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-text);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 12px;
    color: var(--color-primary);
  }
`;

const CardDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--color-text-light);
`;

const ValuesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const ValueCard = styled(motion.div)`
  background-color: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const ValueIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: rgba(93, 30, 95, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: var(--color-primary);
  font-size: 24px;
`;

const ValueTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 0.75rem;
`;

const ValueDescription = styled.p`
  font-size: 1rem;
  line-height: 1.7;
  color: var(--color-text-light);
`;

const BenefitsSection = styled.section`
  padding: 100px 0;
  background: linear-gradient(to bottom, #f8f9fa, white);
`;

const BenefitsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 4rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const BenefitItem = styled(motion.div)`
  display: flex;
  gap: 20px;
`;

const BenefitIcon = styled.div`
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background-color: rgba(93, 30, 95, 0.1);
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const BenefitContent = styled.div`
  flex: 1;
`;

const BenefitTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 0.5rem;
`;

const BenefitDescription = styled.p`
  font-size: 1rem;
  line-height: 1.7;
  color: var(--color-text-light);
`;

const StatsSection = styled.section`
  padding: 80px 0;
  background-color: #0c2340;
  color: white;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const StatCard = styled(motion.div)`
  text-align: center;
  padding: 2rem;
`;

const StatValue = styled.div`
  font-size: 3rem;
  font-weight: 700;
  color: #6978ff;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.div`
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
`;

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.6, ease: "easeOut" }
  }
};

const fadeInRight = {
  hidden: { opacity: 0, x: -30 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: { duration: 0.6, ease: "easeOut" }
  }
};

const fadeInLeft = {
  hidden: { opacity: 0, x: 30 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: { duration: 0.6, ease: "easeOut" }
  }
};

const staggerChildren = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const WhyNavyaTechs = () => {
  const [heroRef, heroInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [visionRef, visionInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [valuesRef, valuesInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [benefitsRef, benefitsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [statsRef, statsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  
  return (
    <PageContainer>
      <HeroSection ref={heroRef}>
        {/* <HeroBg>
          <HeroBgPattern />
        </HeroBg> */}
        <Container>
          <HeroContent>
            <PageTitle
              initial={{ opacity: 0, y: 30 }}
              animate={heroInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              transition={{ duration: 0.8 }}
            >
              Why Choose <span>Navya Techs</span>?
            </PageTitle>
            <Tagline
              initial={{ opacity: 0, y: 30 }}
              animate={heroInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <span>FTTH Connecting World</span> — Your Partner in Advanced Network Design
            </Tagline>
            <Description
              initial={{ opacity: 0, y: 30 }}
              animate={heroInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              For more than 19 years, Navya Techs has been at the forefront of fiber network innovation, 
              helping telecom operators and ISPs design, optimize, and maintain cutting-edge FTTH networks. 
              Our comprehensive platform empowers you to create efficient, scalable, and future-proof 
              network infrastructures with precision and ease.
            </Description>
          </HeroContent>
        </Container>
      </HeroSection>
      
      <Section bg="#f8f9fa" ref={visionRef}>
        <Container>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            animate={visionInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            transition={{ duration: 0.6 }}
          >
            Our Vision & <span>Mission</span>
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 30 }}
            animate={visionInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Guided by clear vision and purpose, we work tirelessly to advance the world of 
            fiber optic networking through innovation and excellence.
          </SectionSubtitle>
          
          <VisionMissionContainer>
            <VisionMissionCard
              initial={{ opacity: 0, x: -30 }}
              animate={visionInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -30 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <CardTitle>
                <FiTarget size={28} /> Our Vision
              </CardTitle>
              <CardDescription>
                To revolutionize the way fiber networks are designed and managed, creating a more 
                connected world where reliable, high-speed internet access is universally available. 
                We envision a future where network operators can effortlessly plan, deploy, and optimize 
                fiber infrastructure, driving digital inclusion across communities worldwide.
              </CardDescription>
            </VisionMissionCard>
            
            <VisionMissionCard
              initial={{ opacity: 0, x: 30 }}
              animate={visionInView ? { opacity: 1, x: 0 } : { opacity: 0, x: 30 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <CardTitle>
                <FiCompass size={28} /> Our Mission
              </CardTitle>
              <CardDescription>
                To provide telecom operators and ISPs with the most intuitive, powerful, and comprehensive 
                FTTH network design and management platform in the industry. We are committed to developing 
                innovative solutions that simplify complex network challenges, enhance operational efficiency, 
                and empower our clients to deliver exceptional service to their customers.
              </CardDescription>
            </VisionMissionCard>
          </VisionMissionContainer>
        </Container>
      </Section>
      
      <Section ref={valuesRef}>
        <Container>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            animate={valuesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            transition={{ duration: 0.6 }}
          >
            Our Core <span>Values</span>
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 30 }}
            animate={valuesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            These principles guide everything we do as we work to transform the world of fiber network design.
          </SectionSubtitle>
          
          <ValuesContainer>
            <ValueCard
              initial={{ opacity: 0, y: 30 }}
              animate={valuesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <ValueIcon>
                <FiAward />
              </ValueIcon>
              <ValueTitle>Excellence</ValueTitle>
              <ValueDescription>
                We are committed to delivering excellence in every aspect of our work, from 
                product development to customer support. We continually refine our solutions 
                to exceed industry standards.
              </ValueDescription>
            </ValueCard>
            
            <ValueCard
              initial={{ opacity: 0, y: 30 }}
              animate={valuesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <ValueIcon>
                <FiTrendingUp />
              </ValueIcon>
              <ValueTitle>Innovation</ValueTitle>
              <ValueDescription>
                We embrace innovation as the key to staying ahead in a rapidly evolving 
                industry. Our team constantly explores new technologies and methodologies 
                to enhance our platform's capabilities.
              </ValueDescription>
            </ValueCard>
            
            <ValueCard
              initial={{ opacity: 0, y: 30 }}
              animate={valuesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              <ValueIcon>
                <FiUsers />
              </ValueIcon>
              <ValueTitle>Customer Focus</ValueTitle>
              <ValueDescription>
                Our customers are at the heart of everything we do. We listen to their needs, 
                understand their challenges, and collaborate closely to develop solutions that 
                truly address their requirements.
              </ValueDescription>
            </ValueCard>
          </ValuesContainer>
        </Container>
      </Section>
      
      <BenefitsSection ref={benefitsRef}>
        <Container>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            animate={benefitsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            transition={{ duration: 0.6 }}
          >
            How We Help <span>FTTH Operators</span>
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 30 }}
            animate={benefitsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Our comprehensive platform addresses the unique challenges faced by FTTH network operators, 
            providing tools and capabilities that transform how you design and manage your infrastructure.
          </SectionSubtitle>
          
          <BenefitsContainer>
            <motion.div
              variants={staggerChildren}
              initial="hidden"
              animate={benefitsInView ? "visible" : "hidden"}
            >
              <BenefitItem variants={fadeInRight}>
                <BenefitIcon>
                  <FiCheckCircle />
                </BenefitIcon>
                <BenefitContent>
                  <BenefitTitle>Intuitive Network Design</BenefitTitle>
                  <BenefitDescription>
                    Our drag-and-drop interface simplifies complex network design, allowing you to create 
                    detailed FTTH architectures without extensive technical knowledge. Visualize network 
                    elements, calculate distances, and validate configurations in a user-friendly environment.
                  </BenefitDescription>
                </BenefitContent>
              </BenefitItem>
              
              <BenefitItem variants={fadeInRight}>
                <BenefitIcon>
                  <FiCheckCircle />
                </BenefitIcon>
                <BenefitContent>
                  <BenefitTitle>Real-Time Performance Monitoring</BenefitTitle>
                  <BenefitDescription>
                    Monitor your entire fiber network in real-time, with instant updates on performance 
                    metrics, signal strength, and connectivity status. Identify potential issues before 
                    they impact service and proactively address network bottlenecks.
                  </BenefitDescription>
                </BenefitContent>
              </BenefitItem>
              
              <BenefitItem variants={fadeInRight}>
                <BenefitIcon>
                  <FiCheckCircle />
                </BenefitIcon>
                <BenefitContent>
                  <BenefitTitle>Comprehensive Device Support</BenefitTitle>
                  <BenefitDescription>
                    Our platform supports a wide range of FTTH network devices, including optical transmitters, 
                    EDFAs, PON-EDFAs, GPON-OLTs, and EPON-OLTs. Design networks with confidence knowing that 
                    your equipment is fully compatible with our solution.
                  </BenefitDescription>
                </BenefitContent>
              </BenefitItem>
            </motion.div>
            
            <motion.div
              variants={staggerChildren}
              initial="hidden"
              animate={benefitsInView ? "visible" : "hidden"}
            >
              <BenefitItem variants={fadeInLeft}>
                <BenefitIcon>
                  <FiCheckCircle />
                </BenefitIcon>
                <BenefitContent>
                  <BenefitTitle>Predictive Maintenance</BenefitTitle>
                  <BenefitDescription>
                    Leverage AI-powered predictions to schedule maintenance before equipment failures occur. 
                    Our system analyzes historical data and performance patterns to alert you to potential 
                    issues, reducing downtime and improving customer satisfaction.
                  </BenefitDescription>
                </BenefitContent>
              </BenefitItem>
              
              <BenefitItem variants={fadeInLeft}>
                <BenefitIcon>
                  <FiCheckCircle />
                </BenefitIcon>
                <BenefitContent>
                  <BenefitTitle>Scalable Solutions</BenefitTitle>
                  <BenefitDescription>
                    Whether you're managing a small local network or a large-scale national infrastructure, 
                    our platform scales with your needs. Easily expand your network design as your business 
                    grows, without compromising on performance or usability.
                  </BenefitDescription>
                </BenefitContent>
              </BenefitItem>
              
              <BenefitItem variants={fadeInLeft}>
                <BenefitIcon>
                  <FiCheckCircle />
                </BenefitIcon>
                <BenefitContent>
                  <BenefitTitle>Remote Configuration</BenefitTitle>
                  <BenefitDescription>
                    Configure and manage your network devices remotely, saving time and reducing operational 
                    costs. Update settings, deploy changes, and troubleshoot issues from a central console, 
                    eliminating the need for on-site visits in many scenarios.
                  </BenefitDescription>
                </BenefitContent>
              </BenefitItem>
            </motion.div>
          </BenefitsContainer>
        </Container>
      </BenefitsSection>
      
      <StatsSection ref={statsRef}>
        <Container>
          <StatsContainer>
            <StatCard
              initial={{ opacity: 0, y: 20 }}
              animate={statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              <StatValue>19+</StatValue>
              <StatLabel>Years Experience</StatLabel>
            </StatCard>
            
            <StatCard
              initial={{ opacity: 0, y: 20 }}
              animate={statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <StatValue>65+</StatValue>
              <StatLabel>Trusted Companies</StatLabel>
            </StatCard>
            
            <StatCard
              initial={{ opacity: 0, y: 20 }}
              animate={statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <StatValue>10K+</StatValue>
              <StatLabel>Networks Designed</StatLabel>
            </StatCard>
            
            <StatCard
              initial={{ opacity: 0, y: 20 }}
              animate={statsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <StatValue>99.9%</StatValue>
              <StatLabel>Platform Uptime</StatLabel>
            </StatCard>
          </StatsContainer>
        </Container>
      </StatsSection>
    </PageContainer>
  );
};

export default WhyNavyaTechs; 