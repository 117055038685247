import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Container from '../components/Container';
import { FiMonitor, FiBell, FiActivity, FiMap, FiTrendingUp, FiSettings } from 'react-icons/fi';

const SectionContainer = styled.section`
  padding: 160px 0 100px;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  position: relative;
  color: white;
  overflow: hidden;
`;

const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/grid-pattern.png') repeat;
  opacity: 0.1;
  z-index: 0;
`;

const HeaderBadge = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  
  svg {
    margin-right: 8px;
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
  text-transform: uppercase;
  
  span {
    color: #6978ff;
  }
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.7;
  margin-bottom: 30px;
`;

const SectionDescription = styled(motion.p)`
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.7;
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: center;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled(motion.div)`
  background: white;
  border-radius: 20px;
  padding: 35px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
`;

const FeatureIconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  background: ${props => props.bgColor || 'rgba(93, 30, 95, 0.1)'};
  color: ${props => props.iconColor || 'var(--color-primary)'};
  font-size: 24px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--color-text);
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: var(--color-text-light);
  line-height: 1.7;
`;

const WhyNavyatechsSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  
  const features = [
    {
      id: 1,
      icon: <FiMonitor />,
      title: "Real-Time Monitoring",
      description: "Monitor your fiber optic network in real-time, with instant updates on performance metrics and status changes.",
      bgColor: "rgba(147, 107, 251, 0.1)",
      iconColor: "#936bfb"
    },
    {
      id: 2,
      icon: <FiBell />,
      title: "Instant Alerts",
      description: "Receive immediate notifications when issues arise, allowing for quick response and minimal downtime.",
      bgColor: "rgba(255, 158, 107, 0.1)",
      iconColor: "#ff9e6b"
    },
    {
      id: 3,
      icon: <FiActivity />,
      title: "Performance Analytics",
      description: "Analyze historical performance data to identify trends and potential issues before they become critical.",
      bgColor: "rgba(93, 30, 95, 0.1)",
      iconColor: "var(--color-primary)"
    },
    {
      id: 4,
      icon: <FiMap />,
      title: "Network Mapping",
      description: "Visualize your entire network infrastructure with interactive maps and location-based insights.",
      bgColor: "rgba(67, 97, 238, 0.1)",
      iconColor: "#4361ee"
    },
    {
      id: 5,
      icon: <FiTrendingUp />,
      title: "Predictive Maintenance",
      description: "Use AI-powered predictions to schedule maintenance before equipment failures occur.",
      bgColor: "rgba(233, 30, 99, 0.1)",
      iconColor: "var(--color-secondary)"
    },
    {
      id: 6,
      icon: <FiSettings />,
      title: "Remote Configuration",
      description: "Configure and manage your network devices remotely, saving time and reducing operational costs.",
      bgColor: "rgba(38, 166, 154, 0.1)",
      iconColor: "#26a69a"
    }
  ];
  
  return (
    <SectionContainer id="why-navyatechs">
      {/* <BackgroundPattern /> */}
      <Container style={{ position: 'relative', zIndex: 1 }}>
        <SectionHeader>
          <HeaderBadge
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" fill="currentColor" />
            </svg>
            About Us
          </HeaderBadge>
          <SectionTitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            WHY CHOOSE <span>NAVYA TECHS</span>?
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.3 }}
          >
            Your trusted partner in network design and management for over 19 years
          </SectionSubtitle>
        </SectionHeader>
        
        <SectionDescription
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          Our comprehensive suite of tools helps you monitor, analyze, and optimize your fiber optic network performance.
        </SectionDescription>
        
        <FeaturesGrid ref={ref}>
          {features.map((feature, index) => (
            <FeatureCard
              key={feature.id}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { 
                  opacity: 1, 
                  y: 0,
                  transition: { 
                    duration: 0.6, 
                    delay: index * 0.1, 
                    ease: "easeOut" 
                  }
                }
              }}
            >
              <FeatureIconWrapper 
                bgColor={feature.bgColor}
                iconColor={feature.iconColor}
              >
                {feature.icon}
              </FeatureIconWrapper>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </FeatureCard>
          ))}
        </FeaturesGrid>
      </Container>
    </SectionContainer>
  );
};

export default WhyNavyatechsSection; 