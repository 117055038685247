import React from 'react';
import styled from 'styled-components';
import Container from '../components/Container';

const PageContainer = styled.div`
  padding: 150px 0 100px;
  background-color: var(--color-light);
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const PageTitle = styled.h1`
  font-size: 2.5rem;
  color: var(--color-secondary-dark);
  margin-bottom: 15px;
`;

const PageSubtitle = styled.p`
  font-size: 1.1rem;
  color: var(--color-text);
  max-width: 700px;
  margin: 0 auto 30px;
  line-height: 1.6;
`;

const UpdatedDate = styled.div`
  font-size: 0.9rem;
  color: var(--color-text-light);
`;

const ContentWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 40px;
  
  @media (max-width: 576px) {
    padding: 30px 20px;
  }
`;

const Section = styled.section`
  margin-bottom: 40px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: var(--color-secondary-dark);
`;

const SectionContent = styled.div`
  color: var(--color-text);
  line-height: 1.7;
  
  p {
    margin-bottom: 15px;
  }
  
  ul {
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  li {
    margin-bottom: 10px;
  }
`;

const Privacy = () => {
  return (
    <PageContainer>
      <Container>
        <PageHeader>
          <PageTitle>Privacy Policy</PageTitle>
          <PageSubtitle>
            This Privacy Policy describes how Navya Techs collects, uses, and shares your personal information when you use our services.
          </PageSubtitle>
          <UpdatedDate>Last Updated: July 1, 2023</UpdatedDate>
        </PageHeader>
        
        <ContentWrapper>
          <Section>
            <SectionTitle>1. Information We Collect</SectionTitle>
            <SectionContent>
              <p>
                We collect several different types of information for various purposes to provide and improve our Service to you:
              </p>
              <ul>
                <li>
                  <strong>Personal Data:</strong> While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
                  <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, State, Province, ZIP/Postal code, City</li>
                    <li>Cookies and Usage Data</li>
                  </ul>
                </li>
                <li>
                  <strong>Usage Data:</strong> We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                </li>
              </ul>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>2. Use of Data</SectionTitle>
            <SectionContent>
              <p>Navya Techs uses the collected data for various purposes:</p>
              <ul>
                <li>To provide and maintain our Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our Service</li>
                <li>To monitor the usage of our Service</li>
                <li>To detect, prevent and address technical issues</li>
              </ul>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>3. Data Security</SectionTitle>
            <SectionContent>
              <p>
                The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>4. Service Providers</SectionTitle>
            <SectionContent>
              <p>
                We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.
              </p>
              <p>
                These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>5. Changes to This Privacy Policy</SectionTitle>
            <SectionContent>
              <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
              </p>
              <p>
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
              </p>
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>6. Contact Us</SectionTitle>
            <SectionContent>
              <p>
                If you have any questions about this Privacy Policy, please contact us:
              </p>
              <ul>
                <li>By email: info@navyatechs.com</li>
                <li>By phone: +91 95740 01157 / +91 78619 86275</li>
                <li>By mail: 126-Navya Techs, Time Square Empire, Mirzapar Bypass Rd, Bhuj-370001</li>
              </ul>
            </SectionContent>
          </Section>
        </ContentWrapper>
      </Container>
    </PageContainer>
  );
};

export default Privacy; 