import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import Navbar from './components/Navbar';
import FooterWrapper from './components/FooterWrapper';
import SupportChatbot from './components/SupportChatbot';
import ScrollToTop from './components/ScrollToTop';
import HeroSection from './sections/HeroSection';
import VideoSection from './sections/VideoSection';
import FeaturesSection from './sections/FeaturesSection';
import WhyNavyatechsSection from './sections/WhyNavyatechsSection';
import SolutionsSection from './sections/SolutionsSection';
import TeamSection from './sections/TeamSection';
import TestimonialsSection from './sections/TestimonialsSection';
import PricingSection from './sections/PricingSection';
import NetworkDevicesSection from './sections/NetworkDevicesSection';
import AboutUsSection from './sections/AboutUsSection';
import MobileAppSection from './sections/MobileAppSection';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import WhyNavyaTechs from './pages/WhyNavyaTechs';
import AboutUs from './pages/AboutUs';
import ErrorBoundary from './ErrorBoundary';

// Import device detail pages
import TransmitterPage from './pages/devices/TransmitterPage';
import EdfaPage from './pages/devices/EdfaPage';
import PonEdfaPage from './pages/devices/PonEdfaPage';
import GponPage from './pages/devices/GponPage';
import GeponPage from './pages/devices/GeponPage';

// WhyNavyatechs Page component
const WhyNavyatechsPage = () => (
  <>
    <ErrorBoundary componentName="Why Navyatechs Section">
      <WhyNavyatechsSection />
    </ErrorBoundary>
    
    <ErrorBoundary componentName="Team Section">
      <TeamSection />
    </ErrorBoundary>
  </>
);

// HomePage component with all sections wrapped in ErrorBoundaries
const HomePage = () => (
  <>
    <ErrorBoundary componentName="Hero Section">
      <HeroSection />
    </ErrorBoundary>
    <ErrorBoundary componentName="Video Section">
      <VideoSection />
    </ErrorBoundary>
    {/* <ErrorBoundary componentName="About Us Section">
      <AboutUsSection />
    </ErrorBoundary> */}
    <ErrorBoundary componentName="Features Section">
      <FeaturesSection />
    </ErrorBoundary>
    {/* <ErrorBoundary componentName="Solutions Section">
      <SolutionsSection />
    </ErrorBoundary> */}
    <ErrorBoundary componentName="Network Devices Section">
      <NetworkDevicesSection />
    </ErrorBoundary>
    <ErrorBoundary componentName="Pricing Section">
      <PricingSection />
    </ErrorBoundary>
    <ErrorBoundary componentName="Testimonials Section">
      <TestimonialsSection />
    </ErrorBoundary>
    <ErrorBoundary componentName="Mobile App Section">
      <MobileAppSection />
    </ErrorBoundary>
  </>
);

// Redirect components for external links
const PrivacyRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://navyatechs.com/privacy';
  }, []);
  return <div>Redirecting to Privacy Policy...</div>;
};

const TermsRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://navyatechs.com/termsandconditions';
  }, []);
  return <div>Redirecting to Terms and Conditions...</div>;
};

function App() {
  return (
    <ErrorBoundary componentName="App Root">
      <Router>
        <GlobalStyles />
        <ScrollToTop />
        <ErrorBoundary componentName="Navigation">
          <Navbar />
        </ErrorBoundary>
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/why-navyatechs" element={
            <ErrorBoundary componentName="Why Navyatechs Page">
              <WhyNavyaTechs />
            </ErrorBoundary>
          } />
          <Route path="/about-us" element={
            <ErrorBoundary componentName="About Us Page">
              <AboutUs />
            </ErrorBoundary>
          } />
          <Route path="/contact" element={
            <ErrorBoundary componentName="Contact Page">
              <Contact />
            </ErrorBoundary>
          } />
          <Route path="/privacy" element={
            <ErrorBoundary componentName="Privacy Page">
              <Privacy />
            </ErrorBoundary>
          } />
          <Route path="/terms" element={
            <ErrorBoundary componentName="Terms Page">
              <Terms />
            </ErrorBoundary>
          } />
          
          {/* Device detail pages */}
          <Route path="/devices/transmitter" element={
            <ErrorBoundary componentName="Transmitter Page">
              <TransmitterPage />
            </ErrorBoundary>
          } />
          <Route path="/devices/edfa" element={
            <ErrorBoundary componentName="EDFA Page">
              <EdfaPage />
            </ErrorBoundary>
          } />
          <Route path="/devices/pon-edfa" element={
            <ErrorBoundary componentName="PON EDFA Page">
              <PonEdfaPage />
            </ErrorBoundary>
          } />
          <Route path="/devices/gpon" element={
            <ErrorBoundary componentName="GPON Page">
              <GponPage />
            </ErrorBoundary>
          } />
          <Route path="/devices/gepon" element={
            <ErrorBoundary componentName="GEPON Page">
              <GeponPage />
            </ErrorBoundary>
          } />
        </Routes>
        
        <ErrorBoundary componentName="Footer Wrapper">
          <FooterWrapper />
        </ErrorBoundary>
        
        <ErrorBoundary componentName="Support Chatbot">
          <SupportChatbot />
        </ErrorBoundary>
      </Router>
    </ErrorBoundary>
  );
}

export default App; 