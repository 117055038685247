import React from 'react';
import { FaMapMarkedAlt, FaNetworkWired, FaSitemap, FaProjectDiagram, FaRuler, FaTools } from 'react-icons/fa';
import DeviceDetailTemplate from '../../components/DeviceDetailTemplate';

const GeponPage = () => {
  const deviceData = {
    deviceName: "GEPON",
    fullName: "Gigabit Ethernet Passive Optical Network Design",
    description: "Navyatechs provides expert network design services incorporating GEPON technology for efficient FTTH deployments. We help you create optimized network architectures leveraging 1.25Gbps symmetric bandwidth while ensuring seamless integration with existing Ethernet infrastructure.",
    image: "/assets/devices/eponimg.png",
    
    specifications: {
      intro: "Our network designs incorporate GEPON systems with these specifications to deliver robust performance:",
      items: [
        "Symmetric 1.25Gbps upstream and downstream bandwidth",
        "Compliant with IEEE 802.3ah/802.3av standards",
        "Support for high-density OLT configurations",
        "Split ratio optimization up to 1:64 for efficient deployment",
        "Transmission distance planning up to 20km",
        "Forward Error Correction (FEC) implementation for improved reliability",
        "Multiple service types support (Layer 2 and Layer 3)",
        "Dynamic Bandwidth Allocation (DBA) optimization",
        "Ethernet OAM integration for comprehensive management",
        "Quality of Service (QoS) planning with multiple queue support",
        "Redundancy planning for critical network components",
        "MPCP (Multi-Point Control Protocol) implementation for efficient upstream transmission"
      ]
    },
    
    features: {
      intro: "Our GEPON network design service provides key advantages for operators deploying FTTH networks:",
      items: [
        "Native Ethernet compatibility for simplified network architecture",
        "Cost-effective network planning compared to other PON technologies",
        "Simplified ONU management strategy through Ethernet protocols",
        "Flexible bandwidth allocation plans based on service needs",
        "Multicast service optimization with efficient bandwidth utilization",
        "Seamless integration planning with existing Ethernet infrastructure",
        "Robust security design including port isolation and encryption",
        "Comprehensive VLAN architecture for service isolation",
        "Advanced QoS design for traffic prioritization",
        "Extensive OAM implementation for troubleshooting and monitoring",
        "Future-proof planning with IPv6 and advanced networking features",
        "Customized ONU deployment strategies for different scenarios"
      ]
    },
    
    useCases: [
      {
        title: "Cost-Efficient FTTH Design",
        description: "We design cost-effective GEPON-based fiber networks where Ethernet compatibility and simplified architecture are priorities, helping you maximize ROI.",
        image: "/assets/usecases/costefficient.jpg"
      },
      {
        title: "Enterprise Network Planning",
        description: "Our designs leverage GEPON's native Ethernet compatibility to create business-class connectivity solutions with seamless corporate network integration.",
        image: "/assets/usecases/enterprise.jpg"
      },
      {
        title: "Campus Network Architecture",
        description: "We create specialized designs for university or corporate campus environments where Ethernet is the dominant protocol and simplified management is essential.",
        image: "/assets/usecases/campus.jpg"
      }
    ],
    
    networkDiagram: {
      description: "Our expert team creates optimized GEPON network designs using advanced planning tools. We develop comprehensive network architectures with precise OLT placement, optimal splitter locations, and strategic ONU deployment to maximize coverage and performance.",
      image: "/assets/diagrams/gepon-network.jpg"
    },
    
    compatibleDevices: {
      intro: "Our network designs incorporate a wide range of compatible components to create a cohesive GEPON-based solution:",
      items: [
        "GEPON ONUs (multiple form factors)",
        "Ethernet Switches and Routers",
        "Optical Splitters",
        "PLC Splitters",
        "WDM Multiplexers",
        "EDFA Amplifiers",
        "PON EDFA Systems",
        "Optical Transmitters",
        "Fiber Distribution Hubs",
        "Fiber Distribution Terminals",
        "FTTH Cables and Connectors",
        "Layer 2/3 Network Equipment"
      ]
    },
    
    designTools: [
      {
        name: "GEPON Network Designer",
        description: "Our expert team uses advanced planning tools to design complete GEPON networks with optimized OLT placement, splitter locations, and ONU deployments.",
        icon: <FaProjectDiagram />
      },
      {
        name: "Ethernet Integration Planner",
        description: "We create detailed plans for seamless integration between your GEPON network and existing Ethernet infrastructure for unified management.",
        icon: <FaNetworkWired />
      },
      {
        name: "Splitter Configuration Tool",
        description: "Our tools determine optimal splitter configurations based on subscriber density, bandwidth requirements, and geographical constraints.",
        icon: <FaSitemap />
      },
      {
        name: "Coverage Analyzer",
        description: "We analyze potential coverage areas based on OLT locations, split ratios, and fiber routes to maximize network efficiency and subscriber reach.",
        icon: <FaMapMarkedAlt />
      },
      {
        name: "Power Budget Calculator",
        description: "Our team calculates end-to-end optical power budgets considering GEPON specifications, splitter losses, and fiber properties to ensure reliable connectivity.",
        icon: <FaRuler />
      },
      {
        name: "Migration Planning Tool",
        description: "We develop smooth migration strategies from existing copper or wireless infrastructure to GEPON with minimal service disruption.",
        icon: <FaTools />
      }
    ]
  };

  return <DeviceDetailTemplate {...deviceData} />;
};

export default GeponPage; 