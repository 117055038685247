import React from 'react';
import { FaMapMarkedAlt, FaNetworkWired, FaSitemap, FaProjectDiagram, FaRuler, FaTools } from 'react-icons/fa';
import DeviceDetailTemplate from '../../components/DeviceDetailTemplate';

const PonEdfaPage = () => {
  const deviceData = {
    deviceName: "PON EDFA",
    fullName: "PON EDFA Network Design Solutions",
    description: "Navyatechs specializes in creating optimized network designs incorporating PON EDFA technology specifically for Passive Optical Network architectures. Our expert designs combine the benefits of EDFA amplification with PON-specific features to maximize performance in FTTH deployments with shared fiber infrastructure.",
    image: "/assets/devices/pon-edfaimg.png",
    
    specifications: {
      intro: "Our network designs incorporate advanced PON EDFA specifications tailored specifically for PON architectures:",
      items: [
        "Design planning for output power from 16dBm to 28dBm",
        "Wavelength optimization for 1540nm to 1565nm with PON compatibility",
        "PON port configuration planning for 4, 8, 16, and 32 port options",
        "Gain flatness planning of ≤ ±0.5dB across operating band",
        "Multi-standard design support: GPON, EPON, XG-PON",
        "WDM integration planning for management wavelengths",
        "Input/output isolation design of > 30dB",
        "Environmental planning for -10°C to +60°C operation",
        "Comprehensive monitoring system design via SNMP, web interface",
        "Optical power monitoring implementation for each port",
        "Equipment housing design with redundant power planning"
      ]
    },
    
    features: {
      intro: "Our PON EDFA network designs offer unique advantages for PON-based FTTH deployments:",
      items: [
        "Architecture optimization for PON compatibility",
        "Multi-port amplification planning for efficient network design",
        "Automatic gain control strategy optimized for PON traffic patterns",
        "Individual port monitoring and management system design",
        "Raman amplification suppression planning for stable operation",
        "Traffic-aware gain adjustment strategies for optimal performance",
        "Protection system design against back-reflection and transients",
        "Advanced failover mechanism planning for high availability",
        "Split ratio optimization for PON networks",
        "Design support for mixed rate PON traffic (GPON/10G-PON)",
        "Low latency amplification planning for time-sensitive services"
      ]
    },
    
    useCases: [
      {
        title: "High Density PON Network Design",
        description: "We create optimized network designs for high-density residential areas that maximize the number of subscribers from a single OLT while maintaining signal quality across all endpoints.",
        image: "/assets/usecases/highdensity.jpg"
      },
      {
        title: "Extended Reach PON Planning",
        description: "Our designs enable PON deployments beyond standard distance limitations, allowing operators to serve subscribers at greater distances from the central office while maintaining quality.",
        image: "/assets/usecases/extendedreach.jpg"
      },
      {
        title: "High Split Ratio Network Architecture",
        description: "We develop network designs that support higher split ratios (1:64, 1:128) while maintaining sufficient power budget for reliable operation at all endpoints.",
        image: "/assets/usecases/highsplit.jpg"
      }
    ],
    
    networkDiagram: {
      description: "Our expert team creates optimized PON network designs with PON-EDFA components strategically placed between OLT equipment and optical splitters. We help you develop efficient network architectures that maximize subscriber reach while maintaining signal quality throughout the network.",
      image: "/assets/diagrams/pon-edfa-network.jpg"
    },
    
    compatibleDevices: {
      intro: "Our network designs incorporate a wide range of compatible components to create cohesive PON-based solutions:",
      items: [
        "GPON OLT Equipment",
        "EPON OLT Systems",
        "XG(S)-PON OLT Equipment",
        "Optical Transmitters",
        "Standard EDFA Amplifiers",
        "PLC Splitters (1:8, 1:16, 1:32, 1:64)",
        "Optical Splitters",
        "ONU/ONT Endpoints",
        "WDM Multiplexers",
        "Fiber Distribution Hubs",
        "Fiber Distribution Terminals",
        "FTTH Cables and Connectivity"
      ]
    },
    
    designTools: [
      {
        name: "PON Network Designer",
        description: "Our team uses advanced tools to design complete PON networks with integrated PON-EDFA placement that optimizes coverage, split ratios, and signal quality.",
        icon: <FaProjectDiagram />
      },
      {
        name: "Split Ratio Optimizer",
        description: "We calculate optimal split ratios for PON networks based on subscriber density, distance, and service level requirements to maximize efficiency.",
        icon: <FaSitemap />
      },
      {
        name: "Coverage Mapping",
        description: "Our experts generate detailed coverage maps showing potential subscriber reach with PON-EDFA deployment at various strategic locations.",
        icon: <FaMapMarkedAlt />
      },
      {
        name: "Power Budget Calculator",
        description: "We calculate end-to-end power budgets for PON networks with PON-EDFA amplification to ensure sufficient signal quality throughout the network.",
        icon: <FaRuler />
      },
      {
        name: "Network Simulation",
        description: "Our team simulates PON network performance with various PON-EDFA configurations to identify the optimal deployment strategy for your specific needs.",
        icon: <FaNetworkWired />
      },
      {
        name: "Installation Planner",
        description: "We create step-by-step deployment plans for PON-EDFA integration with your existing PON infrastructure for smooth implementation.",
        icon: <FaTools />
      }
    ]
  };

  return <DeviceDetailTemplate {...deviceData} />;
};

export default PonEdfaPage; 