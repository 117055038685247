import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FiCheck, FiArrowRight } from 'react-icons/fi';
import Container from '../components/Container';
import { Link } from 'react-router-dom';
import { PackagesAPI } from '../config/api';

const PricingContainer = styled.section`
  padding: 80px 0;
  background-color: var(--color-bg-light);
  position: relative;
  overflow: hidden;
`;

const Shape = styled.div`
  position: absolute;
  border-radius: 50%;
  z-index: 0;
  
  &.shape1 {
    top: -200px;
    left: -200px;
    width: 400px;
    height: 400px;
    background: linear-gradient(135deg, rgba(93, 30, 95, 0.05) 0%, rgba(93, 30, 95, 0.02) 100%);
  }
  
  &.shape2 {
    bottom: -150px;
    right: -150px;
    width: 300px;
    height: 300px;
    background: linear-gradient(135deg, rgba(233, 30, 99, 0.05) 0%, rgba(233, 30, 99, 0.02) 100%);
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
`;

const BadgeContainer = styled(motion.div)`
  display: inline-block;
  background-color: rgba(93, 30, 95, 0.1);
  color: var(--color-primary);
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 50px;
  margin-bottom: 20px;
`;

const PricingTitle = styled(motion.h2)`
  margin-bottom: 15px;
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: 700;
  
  span {
    color: var(--color-primary);
  }
`;

const PricingSubtitle = styled(motion.p)`
  color: var(--color-text-light);
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
`;

const PeriodSwitchContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  gap: 10px;
`;

const PeriodOption = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: ${props => props.active ? '600' : '400'};
  font-size: 0.9rem;
  color: ${props => props.active ? 'white' : 'var(--color-text)'};
  background-color: ${props => props.active ? 'var(--color-primary)' : 'transparent'};
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.active ? 'var(--color-primary)' : 'rgba(93, 30, 95, 0.1)'};
  }
`;

const SaveBadge = styled.span`
  display: inline-block;
  font-size: 0.7rem;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: rgba(76, 175, 80, 0.2);
  color: #4CAF50;
  margin-left: 5px;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 12px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const PlanCard = styled(motion.div)`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  }
`;

const PlanHeader = styled.div`
  padding: 12px 10px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const PlanName = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 6px;
  color: var(--color-text);
`;

const PlanPrice = styled.div`
  margin-bottom: 12px;
`;

const Currency = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  vertical-align: top;
`;

const Amount = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-primary);
`;

const Period = styled.span`
  font-size: 0.75rem;
  color: var(--color-text-light);
  display: block;
  margin-top: 2px;
`;

const UsersSection = styled.div`
  padding: 10px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const SectionLabel = styled.div`
  font-weight: 600;
  margin-bottom: 6px;
  color: var(--color-text);
  font-size: 0.85rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 25px;
    height: 2px;
    background-color: var(--color-primary);
  }
`;

const UserDetails = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(FiCheck)`
  color: var(--color-primary);
  margin-right: 6px;
  font-size: 12px;
`;

const UserFeature = styled.span`
  font-size: 0.8rem;
  color: var(--color-text);
`;

const DurationSection = styled.div`
  padding: 10px 12px;
`;

const DurationOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  
  &:last-child {
    border-bottom: none;
  }
`;

const DurationLabel = styled.span`
  font-size: 0.75rem;
  color: var(--color-text);
`;

const DurationPrice = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PriceValue = styled.span`
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: 2px;
  font-size: 0.8rem;
`;

const SelectButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 3px;
  background-color: rgba(93, 30, 95, 0.1);
  color: var(--color-primary);
  font-size: 0.7rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: var(--color-primary);
    color: white;
  }
`;

const ArrowIcon = styled(FiArrowRight)`
  margin-left: 3px;
  font-size: 10px;
`;

const DemoContainer = styled.div`
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
`;

const DemoCard = styled(motion.div)`
  background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-light) 100%);
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  color: white;
  display: grid;
  grid-template-columns: 3fr 2fr;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
  
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const DemoLeftSide = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DemoRightSide = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

const DemoName = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-align: left;
`;

const DemoPrice = styled.div`
  margin: 15px 0;
  text-align: left;
`;

const DemoAmount = styled.span`
  font-size: 2.5rem;
  font-weight: 700;
`;

const DemoTrialLabel = styled.span`
  font-size: 1rem;
  margin-left: 5px;
`;

const TrialBadge = styled.div`
  display: inline-block;
  padding: 8px 16px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.25);
  margin: 15px 0;
  font-weight: 600;
  align-self: flex-start;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const DemoFeatures = styled.div`
  margin: 15px 0;
  text-align: left;
`;

const DemoFeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.9rem;
`;

const DemoStartButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  border-radius: 30px;
  background-color: white;
  color: var(--color-primary);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    transform: scale(1.05);
  }
`;

const GstNote = styled.div`
  text-align: center;
  margin-top: 30px;
  font-size: 0.9rem;
  color: #f44336;
`;

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const PricingSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const [pricingPeriod, setPricingPeriod] = useState('monthly');
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch pricing packages from the API
    const fetchPackages = async () => {
      try {
        const response = await PackagesAPI.getPackagesAllData();
        if (response && response.data && response.data.data) {
          setPackages(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching pricing packages:', error);
        setLoading(false);
      }
    };

    fetchPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PricingContainer id="pricing" ref={ref}>
      <Shape className="shape1" />
      <Shape className="shape2" />
      <Container>
        <SectionHeader>
          <BadgeContainer
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeIn}
          >
            Pricing Plans
          </BadgeContainer>
          <PricingTitle
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeIn}
          >
            CHOOSE THE <span>RIGHT PLAN</span> FOR YOUR NETWORK
          </PricingTitle>
          <PricingSubtitle
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeIn}
          >
            Flexible options to suit your business needs
          </PricingSubtitle>
        </SectionHeader>

        {loading ? (
          <div style={{ textAlign: 'center', padding: '50px 0' }}>Loading pricing plans...</div>
        ) : packages.length > 0 ? (
          <>
            {/* Demo Plan - Separate from grid */}
            {packages.find(pkg => pkg.name === 'Demo') && (
              <DemoContainer>
                <DemoCard
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={fadeIn}
                >
                  <DemoLeftSide>
                    <DemoName>Demo</DemoName>
                    <DemoPrice>
                      <Currency>₹</Currency>
                      <DemoAmount>0</DemoAmount>
                      <DemoTrialLabel>/trial</DemoTrialLabel>
                    </DemoPrice>
                    <TrialBadge>5 Days Free Trial</TrialBadge>
                    <DemoFeatures>
                      <DemoFeatureItem>
                        <CheckIcon />
                        <span>1 Port Users</span>
                      </DemoFeatureItem>
                      <DemoFeatureItem>
                        <CheckIcon />
                        <span>1 Map Users</span>
                      </DemoFeatureItem>
                      <DemoFeatureItem>
                        <CheckIcon />
                        <span>Full Access to All Features</span>
                      </DemoFeatureItem>
                      <DemoFeatureItem>
                        <CheckIcon />
                        <span>No Credit Card Required</span>
                      </DemoFeatureItem>
                    </DemoFeatures>
                  </DemoLeftSide>
                  <DemoRightSide>
                    <DemoStartButton to="https://app.navyatechs.com/register">
                      Start Free Trial <ArrowIcon />
                    </DemoStartButton>
                  </DemoRightSide>
                </DemoCard>
              </DemoContainer>
            )}

            <PeriodSwitchContainer
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={fadeIn}
            >
              <PeriodOption 
                active={pricingPeriod === 'monthly'}
                onClick={() => setPricingPeriod('monthly')}
              >
                Monthly
              </PeriodOption>
              <PeriodOption 
                active={pricingPeriod === 'annual'}
                onClick={() => setPricingPeriod('annual')}
              >
                Annual <SaveBadge>Save 20%</SaveBadge>
              </PeriodOption>
              <PeriodOption 
                active={pricingPeriod === '24months'}
                onClick={() => setPricingPeriod('24months')}
              >
                24 Months <SaveBadge>Save 25%</SaveBadge>
              </PeriodOption>
            </PeriodSwitchContainer>

            {/* Regular Plans Grid */}
            <PricingGrid>
              {packages.filter(pkg => pkg.name !== 'Demo').map((pkg, index) => (
                <PlanCard
                  key={pkg.id || index}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={fadeIn}
                  transition={{ delay: 0.1 * (index + 1) }}
                >
                  <PlanHeader>
                    <PlanName>{pkg.name}</PlanName>
                    <PlanPrice>
                      <Currency>₹</Currency>
                      <Amount>
                        {pricingPeriod === 'monthly' && pkg.one_month_price}
                        {pricingPeriod === 'annual' && pkg.twelve_month_price && (pkg.twelve_month_price / 12).toFixed(0)}
                        {pricingPeriod === '24months' && pkg.twenty_four_month_price && (pkg.twenty_four_month_price / 24).toFixed(0)}
                      </Amount>
                      <Period>/month</Period>
                    </PlanPrice>
                  </PlanHeader>

                  <UsersSection>
                    <SectionLabel>Users</SectionLabel>
                    <UserDetails>
                      <CheckIcon />
                      <UserFeature>{pkg.port_user} Port Users</UserFeature>
                    </UserDetails>
                    <UserDetails>
                      <CheckIcon />
                      <UserFeature>{pkg.port_user} Map Users</UserFeature>
                    </UserDetails>
                  </UsersSection>

                  <DurationSection>
                    <SectionLabel>Duration Options</SectionLabel>
                    
                    {pkg.one_month_price && (
                      <DurationOption>
                        <DurationLabel>1 Month</DurationLabel>
                        <DurationPrice>
                          <PriceValue>₹{pkg.one_month_price}</PriceValue>
                          <SelectButton to="https://app.navyatechs.com/register">
                            Select <ArrowIcon />
                          </SelectButton>
                        </DurationPrice>
                      </DurationOption>
                    )}
                    
                    {pkg.three_month_price && (
                      <DurationOption>
                        <DurationLabel>3 Months</DurationLabel>
                        <DurationPrice>
                          <PriceValue>₹{pkg.three_month_price}</PriceValue>
                          <SelectButton to="https://app.navyatechs.com/register">
                            Select <ArrowIcon />
                          </SelectButton>
                        </DurationPrice>
                      </DurationOption>
                    )}
                    
                    {pkg.six_month_price && (
                      <DurationOption>
                        <DurationLabel>6 Months</DurationLabel>
                        <DurationPrice>
                          <PriceValue>₹{pkg.six_month_price}</PriceValue>
                          <SelectButton to="https://app.navyatechs.com/register">
                            Select <ArrowIcon />
                          </SelectButton>
                        </DurationPrice>
                      </DurationOption>
                    )}
                    
                    {pkg.twelve_month_price && (
                      <DurationOption>
                        <DurationLabel>12 Months</DurationLabel>
                        <DurationPrice>
                          <PriceValue>₹{pkg.twelve_month_price}</PriceValue>
                          <SelectButton to="https://app.navyatechs.com/register">
                            Select <ArrowIcon />
                          </SelectButton>
                        </DurationPrice>
                      </DurationOption>
                    )}
                    
                    {pkg.twenty_four_month_price && (
                      <DurationOption>
                        <DurationLabel>24 Months</DurationLabel>
                        <DurationPrice>
                          <PriceValue>₹{pkg.twenty_four_month_price}</PriceValue>
                          <SelectButton to="https://app.navyatechs.com/register">
                            Select <ArrowIcon />
                          </SelectButton>
                        </DurationPrice>
                      </DurationOption>
                    )}
                  </DurationSection>
                </PlanCard>
              ))}
            </PricingGrid>
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '50px 0' }}>
            <p>Unable to load pricing plans. Please try again later.</p>
          </div>
        )}

        <GstNote>Note: GST charges will extra in this all payment</GstNote>
      </Container>
    </PricingContainer>
  );
};

export default PricingSection; 