import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FiPlay, FiX } from 'react-icons/fi';
import Container from '../components/Container';

const VideoSectionContainer = styled.section`
  padding: 120px 0 100px;
  background: linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
  margin-top: -1px; /* Connect with wave */
`;

// Add wave transition at the top
const WaveTransition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23f8f9fa' fill-opacity='1' d='M0,192L48,176C96,160,192,128,288,133.3C384,139,480,181,576,186.7C672,192,768,160,864,154.7C960,149,1056,171,1152,165.3C1248,160,1344,128,1392,112L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: cover;
  background-position: bottom;
  pointer-events: none;
  z-index: 1;
`;

const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(rgba(30, 87, 153, 0.1) 1px, transparent 1px);
  background-size: 20px 20px;
  opacity: 0.5;
  pointer-events: none;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SectionDescription = styled(motion.p)`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 50px;
  font-size: 1.1rem;
  color: var(--color-text-light);
  line-height: 1.6;
`;

const VideoWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  aspect-ratio: 16 / 9;
  background: #000;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(12, 35, 64, 0.2) 0%, rgba(30, 87, 153, 0.2) 100%);
    z-index: 1;
    pointer-events: none;
  }
`;

const VideoThumbnail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), 
                    url('/images/network-monitoring-dashboard.jpg');
  background-size: cover;
  background-position: center;
  background-color: #0c2340; /* Fallback if image doesn't load */
  transition: opacity 0.3s ease;
  opacity: ${props => props.isPlaying ? 0 : 1};
  z-index: 2;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(12, 35, 64, 0.8) 0%,
      rgba(30, 87, 153, 0.8) 100%
    );
    z-index: -1;
  }
  
  /* Fallback content if image doesn't load */
  &::after {
    content: 'Network Monitoring Dashboard';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-align: center;
    opacity: 0.8;
  }
`;

const PlayButton = styled(motion.button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  
  svg {
    font-size: 30px;
    color: #0c2340;
  }
  
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
    
    svg {
      font-size: 24px;
    }
  }
`;

const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: ${props => props.isPlaying ? 4 : 1};
  opacity: ${props => props.isLoaded ? 1 : 0};
  transition: opacity 0.3s ease;
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: ${props => props.isPlaying ? 1 : 0};
  pointer-events: ${props => props.isPlaying ? 'auto' : 'none'};
  
  svg {
    font-size: 20px;
    color: #0c2340;
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  z-index: 3;
  animation: spin 1s ease-in-out infinite;
  display: ${props => props.isPlaying && !props.isLoaded ? 'block' : 'none'};
  
  @keyframes spin {
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8 } }
};

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const scaleIn = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1, 
    transition: { 
      duration: 0.6,
      ease: "easeOut"
    } 
  }
};

// Added below-video content
const BelowVideoContent = styled(motion.div)`
  margin-top: 50px;
  text-align: center;
`;

const FeaturesList = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 30px auto;
  max-width: 900px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureItem = styled(motion.div)`
  background: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #0c2340;
`;

const FeatureDescription = styled.p`
  font-size: 0.95rem;
  color: var(--color-text-light);
  line-height: 1.5;
`;

const CtaButton = styled(motion.a)`
  display: inline-block;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  font-weight: 600;
  margin-top: 20px;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
`;

const VideoSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const handlePlay = () => {
    setIsPlaying(true);
    setIsLoaded(false);
  };

  const handleClose = () => {
    setIsPlaying(false);
    setIsLoaded(false);
  };

  const handleVideoLoad = () => {
    setIsLoaded(true);
  };

  // Add effect to handle iframe load event
  useEffect(() => {
    if (isPlaying) {
      const timer = setTimeout(() => {
        setIsLoaded(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isPlaying]);

  const features = [
    {
      title: "Real-time Monitoring",
      description: "Monitor your network devices in real-time with customizable alerts and notifications."
    },
    {
      title: "Performance Analytics",
      description: "Gain insights into your network performance with detailed analytics and reports."
    },
    {
      title: "Easy Integration",
      description: "Simple integration with your existing infrastructure and third-party tools."
    }
  ];

  return (
    <VideoSectionContainer id="video-demo" ref={ref}>
      <WaveTransition />
      <BackgroundPattern />
      <Container>
        <SectionTitle
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={fadeInUp}
        >
          See Our Network Monitoring in Action
        </SectionTitle>
        <SectionDescription
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={{
            ...fadeInUp,
            visible: { ...fadeInUp.visible, transition: { delay: 0.2, duration: 0.6 } }
          }}
        >
          Watch how our powerful network monitoring solution helps businesses identify issues before they cause downtime, manage devices efficiently, and optimize network performance.
        </SectionDescription>
        
        <VideoWrapper
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={scaleIn}
        >
          <VideoThumbnail isPlaying={isPlaying} />
          
          {!isPlaying && (
            <PlayButton
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={handlePlay}
            >
              <FiPlay />
            </PlayButton>
          )}
          
          <Loader isPlaying={isPlaying} isLoaded={isLoaded} />
          
          <VideoFrame 
            src={isPlaying ? "https://www.youtube.com/embed/9_gQ6H4pHiU?autoplay=1" : ""} 
            title="Network Monitoring Demo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            isPlaying={isPlaying}
            isLoaded={isLoaded}
            onLoad={handleVideoLoad}
          />
          
          <CloseButton
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleClose}
            isPlaying={isPlaying}
          >
            <FiX />
          </CloseButton>
        </VideoWrapper>
        
        <BelowVideoContent
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={{
            ...fadeInUp,
            visible: { ...fadeInUp.visible, transition: { delay: 0.4, duration: 0.6 } }
          }}
        >
          <FeaturesList>
            {features.map((feature, index) => (
              <FeatureItem 
                key={index}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={{
                  ...fadeInUp,
                  visible: { ...fadeInUp.visible, transition: { delay: 0.5 + (index * 0.1), duration: 0.6 } }
                }}
              >
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureItem>
            ))}
          </FeaturesList>
          
          <CtaButton 
            href="#features"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
          >
            Explore Features
          </CtaButton>
        </BelowVideoContent>
      </Container>
    </VideoSectionContainer>
  );
};

export default VideoSection; 