import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiMail, FiPhone, FiMapPin, FiFacebook, FiTwitter, FiLinkedin, FiInstagram } from 'react-icons/fi';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import Container from './Container';

const FooterContainer = styled.footer`
  background: transparent; /* No background as it will be in the wrapper */
  color: white;
  padding: 60px 0 30px;
  position: relative;
  overflow: hidden;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 40px;
  position: relative;
  z-index: 10;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const FooterColumn = styled.div``;

const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
  img {
    height: 40px;
    margin-right: 10px;
  }
  
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    margin: 0;
  }
`;

const FooterAbout = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 25px;
  line-height: 1.6;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: white;
  transition: var(--transition);
  
  &:hover {
    background-color: #6978ff;
    transform: translateY(-3px);
  }
`;

const FooterTitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 25px;
  color: white;
  position: relative;
  padding-bottom: 12px;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 2px;
    background: linear-gradient(135deg, #6978ff 0%, #1e5799 100%);
  }
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterLink = styled.li`
  margin-bottom: 12px;
`;

const FooterLinkItem = styled(Link)`
  color: rgba(255, 255, 255, 0.7);
  transition: var(--transition);
  display: inline-block;
  
  &:hover {
    color: #6978ff;
    transform: translateX(5px);
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
`;

const ContactIcon = styled.div`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #6978ff;
`;

const ContactText = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  line-height: 1.5;
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);
  margin: 40px 0 30px;
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
`;

const Copyright = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
`;

const FooterBottomLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const FooterBottomLink = styled(Link)`
  color: rgba(255, 255, 255, 0.7);
  transition: var(--transition);
  
  &:hover {
    color: #6978ff;
  }
`;

const Footer = () => {
  return (
    <FooterContainer id="contact">
      <Container>
        <FooterContent>
          <FooterColumn>
            <FooterLogo>
              <img src="/navyalogo.png" alt="Navya Techs" />
            </FooterLogo>
            <FooterAbout>
              Reliable network design and monitoring solutions for telecom operators and enterprises.
            </FooterAbout>
            <SocialLinks>
              <SocialIcon href="https://www.facebook.com/people/Navya-Techs/pfbid0SdShqJUWo49hjamdLHi5Bq1UH33A8UY1tVveLc46Hk1RVPNZCbS4U7gFtCmgGDXAl/" target="_blank" rel="noopener noreferrer">
                <FiFacebook />
              </SocialIcon>
              <SocialIcon href="https://twitter.com/navyatechs" target="_blank" rel="noopener noreferrer">
                <FiTwitter />
              </SocialIcon>
              <SocialIcon href="https://www.linkedin.com/in/navya-tech/" target="_blank" rel="noopener noreferrer">
                <FiLinkedin />
              </SocialIcon>
              <SocialIcon href="https://www.instagram.com/navyatechs/" target="_blank" rel="noopener noreferrer">
                <FiInstagram />
              </SocialIcon>
            </SocialLinks>
          </FooterColumn>
          
          <FooterColumn>
            <FooterTitle>Quick Links</FooterTitle>
            <FooterLinks>
              <FooterLink>
                <FooterLinkItem to="/">Home</FooterLinkItem>
              </FooterLink>
              <FooterLink>
                <FooterLinkItem to="/#features">Features</FooterLinkItem>
              </FooterLink>
              <FooterLink>
                <FooterLinkItem to="/#solutions">Solutions</FooterLinkItem>
              </FooterLink>
              <FooterLink>
                <FooterLinkItem to="/#pricing">Pricing</FooterLinkItem>
              </FooterLink>
              <FooterLink>
                <FooterLinkItem to="https://app.navyatechs.com/login">Sign In</FooterLinkItem>
              </FooterLink>
            </FooterLinks>
          </FooterColumn>
          
          <FooterColumn>
            <FooterTitle>Solutions</FooterTitle>
            <FooterLinks>
              <FooterLink>
                <FooterLinkItem to="/#solutions">Enterprise Network</FooterLinkItem>
              </FooterLink>
              <FooterLink>
                <FooterLinkItem to="/#solutions">ISP Network</FooterLinkItem>
              </FooterLink>
              <FooterLink>
                <FooterLinkItem to="/#solutions">Data Center Solutions</FooterLinkItem>
              </FooterLink>
              <FooterLink>
                <FooterLinkItem to="/#solutions">Network Analytics</FooterLinkItem>
              </FooterLink>
              <FooterLink>
                <FooterLinkItem to="/#solutions">Remote Monitoring</FooterLinkItem>
              </FooterLink>
            </FooterLinks>
          </FooterColumn>
          
          <FooterColumn>
            <FooterTitle>Contact Us</FooterTitle>
            <ContactInfo>
              <ContactIcon>
                <FiMapPin />
              </ContactIcon>
              <ContactText>126-Navya Techs, Time Square Empire, Mirzapar Bypass Rd, Bhuj-370001</ContactText>
            </ContactInfo>
            <ContactInfo>
              <ContactIcon>
                <FiPhone />
              </ContactIcon>
              <ContactText>+91 95740 01157 / +91 78619 86275</ContactText>
            </ContactInfo>
            <ContactInfo>
              <ContactIcon>
                <FiMail />
              </ContactIcon>
              <ContactText>info@navyatechs.com / support@navyatechs.com</ContactText>
            </ContactInfo>
          </FooterColumn>
        </FooterContent>
        {/*  */}
        <Divider />
        
        <FooterBottom>
          <Copyright>
            © {new Date().getFullYear()} Navya Techs. All rights reserved.
          </Copyright>
          <FooterBottomLinks>
            <FooterBottomLink to="/privacy">Privacy Policy</FooterBottomLink>
            <FooterBottomLink to="/terms">Terms of Service</FooterBottomLink>
          </FooterBottomLinks>
        </FooterBottom>
      </Container>
    </FooterContainer>
  );
};

export default Footer; 