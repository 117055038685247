import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Container from '../components/Container';
import Button from '../components/Button';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const SolutionsContainer = styled.section`
  padding: 100px 0;
  position: relative;
  background-color: white;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const SolutionsTitle = styled(motion.h2)`
  margin-bottom: 20px;
  
  span {
    color: var(--color-primary);
  }
`;

const SolutionsSubtitle = styled(motion.p)`
  color: var(--color-text-light);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
`;

const SolutionWrapper = styled.div`
  margin-bottom: 100px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const SolutionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  
  @media (max-width: 1024px) {
    flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
    gap: 30px;
  }
`;

const SolutionContent = styled.div`
  flex: 1;
`;

const SolutionImageWrapper = styled(motion.div)`
  flex: 1;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 20px;
    ${({ reverse }) => (reverse ? 'right: 20px;' : 'left: 20px;')}
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    background: linear-gradient(135deg, rgba(93, 30, 95, 0.15) 0%, rgba(233, 30, 99, 0.15) 100%);
    z-index: -1;
  }
`;

const SolutionImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
`;

const SolutionTitle = styled(motion.h3)`
  margin-bottom: 20px;
  font-size: 2rem;
  
  span {
    color: var(--color-primary);
  }
`;

const SolutionDescription = styled(motion.p)`
  color: var(--color-text-light);
  margin-bottom: 25px;
  line-height: 1.8;
`;

const FeatureList = styled(motion.ul)`
  margin-bottom: 30px;
`;

const FeatureItem = styled(motion.li)`
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  
  &::before {
    content: '✓';
    color: var(--color-primary);
    margin-right: 10px;
    font-weight: bold;
  }
`;

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const solutions = [
  {
    id: 1,
    title: "Enterprise Network Monitoring",
    subtitle: "For Large-Scale Organizations",
    description: "Comprehensive monitoring and management platform specifically designed for enterprise networks with multiple locations and complex infrastructure.",
    features: [
      "Real-time visibility across distributed networks",
      "Centralized management console",
      "Custom alerts and reporting",
      "Integration with existing enterprise systems",
      "Compliance and security monitoring"
    ],
    image: "/solutions/enterprise-monitoring.png"
  },
  {
    id: 2,
    title: "ISP Network Management",
    subtitle: "For Internet Service Providers",
    description: "Specialized tools for ISPs to monitor and maintain fiber optic and broadband networks while ensuring optimal service delivery to customers.",
    features: [
      "Customer bandwidth monitoring",
      "Service quality analytics",
      "Fault detection and isolation",
      "Automated troubleshooting",
      "Customer usage reporting"
    ],
    image: "/solutions/isp-management.png"
  },
  {
    id: 3,
    title: "Data Center Connectivity",
    subtitle: "For IT Infrastructure Teams",
    description: "Advanced connectivity monitoring solutions for data centers, ensuring reliable performance, redundancy, and efficient resource utilization.",
    features: [
      "Server and application connectivity tracking",
      "Bandwidth optimization",
      "Redundancy path verification",
      "Power and cooling integration",
      "Capacity planning tools"
    ],
    image: "/solutions/data-center.png"
  }
];

const Solution = ({ solution, index }) => {
  const isReverse = index % 2 !== 0;
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  
  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  
  return (
    <SolutionWrapper ref={ref}>
      <SolutionItem reverse={isReverse}>
        {!isReverse && (
          <SolutionImageWrapper
            reverse={isReverse}
            initial={{ opacity: 0, x: -50 }}
            animate={controls}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <SolutionImage src={solution.image} alt={solution.title} />
          </SolutionImageWrapper>
        )}
        
        <SolutionContent>
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            animate={controls}
          >
            <SolutionTitle variants={fadeInUp}>
              {solution.title} <span>Solution</span>
            </SolutionTitle>
            
            <SolutionDescription variants={fadeInUp}>
              {solution.description}
            </SolutionDescription>
            
            <FeatureList variants={staggerContainer}>
              {solution.features.map((feature, idx) => (
                <FeatureItem 
                  key={idx}
                  variants={fadeInUp}
                >
                  {feature}
                </FeatureItem>
              ))}
            </FeatureList>
            
            <motion.div variants={fadeInUp}>
              <Button
                as={Link}
                to="http://localhost:3000/register"
                gradient
              >
                Learn More <FiArrowRight />
              </Button>
            </motion.div>
          </motion.div>
        </SolutionContent>
        
        {isReverse && (
          <SolutionImageWrapper
            reverse={isReverse}
            initial={{ opacity: 0, x: 50 }}
            animate={controls}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <SolutionImage src={solution.image} alt={solution.title} />
          </SolutionImageWrapper>
        )}
      </SolutionItem>
    </SolutionWrapper>
  );
};

const SolutionsSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  
  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  
  return (
    <SolutionsContainer id="solutions">
      <Container>
        <SectionHeader ref={ref}>
          <SolutionsTitle
            variants={fadeInUp}
            initial="hidden"
            animate={controls}
          >
            Tailored <span>Solutions</span> for Different Needs
          </SolutionsTitle>
          <SolutionsSubtitle
            variants={fadeInUp}
            initial="hidden"
            animate={controls}
            transition={{ delay: 0.2 }}
          >
            We offer specialized solutions to address the unique challenges of different network environments.
          </SolutionsSubtitle>
        </SectionHeader>
        
        {solutions.map((solution, index) => (
          <Solution key={solution.id} solution={solution} index={index} />
        ))}
      </Container>
    </SolutionsContainer>
  );
};

export default SolutionsSection; 