import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Container from '../components/Container';
import { FiLinkedin, FiTwitter, FiMail } from 'react-icons/fi';

const TeamContainer = styled.section`
  padding: 100px 0;
  background-color: #fff;
  position: relative;
  overflow: hidden;
`;

const Shape = styled.div`
  position: absolute;
  border-radius: 50%;
  
  &.shape1 {
    top: -150px;
    left: -100px;
    width: 300px;
    height: 300px;
    background: linear-gradient(135deg, rgba(93, 30, 95, 0.05) 0%, rgba(93, 30, 95, 0.1) 100%);
  }
  
  &.shape2 {
    bottom: -100px;
    right: -100px;
    width: 250px;
    height: 250px;
    background: linear-gradient(135deg, rgba(233, 30, 99, 0.05) 0%, rgba(233, 30, 99, 0.1) 100%);
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 70px;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-text);
  
  span {
    color: var(--color-primary);
  }
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--color-text-light);
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const TeamMember = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  }
`;

const MemberImage = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.6s ease;
  }
  
  ${TeamMember}:hover & img {
    transform: scale(1.05);
  }
`;

const MemberInfo = styled.div`
  padding: 25px 20px;
  text-align: center;
`;

const MemberName = styled.h3`
  margin: 0 0 5px;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-text);
`;

const MemberRole = styled.p`
  margin: 0 0 10px;
  font-size: 1rem;
  color: var(--color-primary);
  font-weight: 600;
`;

const MemberQualification = styled.p`
  margin: 0 0 15px;
  font-size: 0.9rem;
  color: var(--color-text-light);
`;

const MemberBio = styled.p`
  margin: 0 0 20px;
  font-size: 0.95rem;
  color: var(--color-text-light);
  line-height: 1.6;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: var(--color-text);
  transition: background-color 0.3s ease, color 0.3s ease;
  
  &:hover {
    background-color: var(--color-primary);
    color: white;
  }
`;

// Team member data
const teamMembers = [
  {
    id: 1,
    name: "Jigar Patel",
    role: "CEO & Creative Director",
    qualification: "BE, Electronic and Communication Engineering",
    bio: "Jigar Patel is the CEO and Founder of Navya Techs, leading the company's vision and strategic direction.",
    image: "/team/jigar-patel.jpg",
    social: {
      linkedin: "#",
      twitter: "#",
      email: "mailto:info@navyatechs.com"
    }
  },
  {
    id: 2,
    name: "Uday Ramani",
    role: "Technical Head",
    qualification: "BCA",
    bio: "Uday Ramani leads the technical development team, ensuring the highest quality solutions for our clients.",
    image: "/team/uday-ramani.jpg",
    social: {
      linkedin: "#",
      twitter: "#",
      email: "mailto:info@navyatechs.com"
    }
  },
  {
    id: 3,
    name: "Team Member",
    role: "Network Specialist",
    qualification: "MS, Computer Networks",
    bio: "Specializes in optimizing network infrastructure and implementing advanced monitoring solutions.",
    image: "/team/placeholder.jpg",
    social: {
      linkedin: "#",
      twitter: "#",
      email: "mailto:info@navyatechs.com"
    }
  }
];

const TeamSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  
  return (
    <TeamContainer id="team">
      <Shape className="shape1" />
      <Shape className="shape2" />
      <Container>
        <SectionHeader>
          <SectionTitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, ease: "easeOut" }}
          >
            Meet Our <span>Team</span>
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.1, ease: "easeOut" }}
          >
            The dedicated professionals behind Navyatechs' innovative solutions
          </SectionSubtitle>
        </SectionHeader>
        
        <TeamGrid ref={ref}>
          {teamMembers.map((member, index) => (
            <TeamMember
              key={member.id}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { 
                  opacity: 1, 
                  y: 0,
                  transition: { 
                    duration: 0.6, 
                    delay: index * 0.2, 
                    ease: "easeOut" 
                  }
                }
              }}
            >
              <MemberImage>
                <img 
                  src={member.image} 
                  alt={member.name}
                  onError={(e) => {
                    e.target.src = "https://placehold.co/300x300/e9ecef/5D1E5F.png?text=Team+Member";
                  }}
                />
              </MemberImage>
              <MemberInfo>
                <MemberName>{member.name}</MemberName>
                <MemberRole>{member.role}</MemberRole>
                <MemberQualification>{member.qualification}</MemberQualification>
                <MemberBio>{member.bio}</MemberBio>
                <SocialLinks>
                  <SocialLink href={member.social.linkedin} target="_blank" rel="noopener noreferrer">
                    <FiLinkedin />
                  </SocialLink>
                  <SocialLink href={member.social.twitter} target="_blank" rel="noopener noreferrer">
                    <FiTwitter />
                  </SocialLink>
                  <SocialLink href={member.social.email}>
                    <FiMail />
                  </SocialLink>
                </SocialLinks>
              </MemberInfo>
            </TeamMember>
          ))}
        </TeamGrid>
      </Container>
    </TeamContainer>
  );
};

export default TeamSection; 