import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

try {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} catch (error) {
  console.error('Error rendering the app:', error);
  
  // Render a fallback UI that shows the error
  root.render(
    <div style={{ 
      padding: '20px', 
      maxWidth: '800px', 
      margin: '0 auto', 
      fontFamily: 'Arial, sans-serif' 
    }}>
      <h1 style={{ color: '#d32f2f' }}>Something went wrong</h1>
      <p>There was an error loading the application:</p>
      <pre style={{ 
        background: '#f5f5f5', 
        padding: '15px', 
        borderRadius: '4px',
        overflow: 'auto'
      }}>
        {error.toString()}
      </pre>
      <p>Check the console for more details.</p>
    </div>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
