import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import Container from '../components/Container';

const CtaContainer = styled.section`
  padding: 120px 0 60px;
  background: transparent; /* No background as it will be in the wrapper */
  position: relative;
  overflow: hidden;
  color: white;
  margin-bottom: 0;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const ShapePattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/grid-pattern.png');
  opacity: 0.1;
  z-index: 1;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 25% 25%, rgba(105, 120, 255, 0.3) 0%, transparent 50%),
              radial-gradient(circle at 75% 75%, rgba(105, 120, 255, 0.3) 0%, transparent 50%);
  z-index: 1;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

const CtaTitle = styled(motion.h2)`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const CtaText = styled(motion.p)`
  font-size: 1.2rem;
  margin-bottom: 40px;
  opacity: 0.9;
  line-height: 1.6;
`;

const ButtonsContainer = styled(motion.div)`
  display: flex;
  gap: 20px;
  justify-content: center;
  
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledButton = styled(motion.a)`
  display: inline-block;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease;
  
  &.primary {
    background-color: #6978ff;
    color: white;
    border: none;
    
    &:hover {
      background-color: #8590ff;
      transform: translateY(-3px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
  
  &.outline {
    background-color: transparent;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.5);
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      transform: translateY(-3px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
`;

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const CtaSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <CtaContainer id="cta" ref={ref}>
      <Container>
        <ContentWrapper>
          <CtaTitle
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={fadeInUp}
          >
            Ready to Transform Your Network Management?
          </CtaTitle>
          <CtaText
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={{
              ...fadeInUp,
              visible: { ...fadeInUp.visible, transition: { delay: 0.2, duration: 0.6 } }
            }}
          >
            Join hundreds of businesses that have already improved their network reliability
            and reduced downtime with our cutting-edge monitoring solution.
          </CtaText>
          <ButtonsContainer
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={{
              ...fadeInUp,
              visible: { ...fadeInUp.visible, transition: { delay: 0.4, duration: 0.6 } }
            }}
          >
            <StyledButton 
              className="primary"
              href="https://app.navyatechs.com/register" 
              target="_blank"
              whileHover={{ y: -3, boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)" }}
              whileTap={{ y: -1, boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)" }}
            >
              Start Free Trial
            </StyledButton>
            <StyledButton 
              className="outline"
              as={Link} 
              to="/contact"
              whileHover={{ y: -3, boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)" }}
              whileTap={{ y: -1, boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)" }}
            >
              Schedule Demo
            </StyledButton>
          </ButtonsContainer>
        </ContentWrapper>
      </Container>
    </CtaContainer>
  );
};

export default CtaSection; 