import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Container from '../components/Container';
import { 
  FiUser, 
  FiMail, 
  FiPhone, 
  FiMessageSquare, 
  FiSend, 
  FiMapPin,
  FiHeadphones,
  FiClock,
  FiFacebook,
  FiTwitter,
  FiLinkedin,
  FiInstagram
} from 'react-icons/fi';

const ContactContainer = styled.section`
  padding: 120px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  position: relative;
  overflow: hidden;
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 70px;
`;

const PageTitle = styled(motion.h1)`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-text);
  
  span {
    color: var(--color-primary);
  }
`;

const PageSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--color-text-light);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
`;

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 50px;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const ContactInfo = styled(motion.div)`
  background-color: var(--color-primary);
  border-radius: 20px;
  padding: 40px;
  color: white;
  height: fit-content;
  
  @media (max-width: 992px) {
    padding: 30px;
  }
`;

const InfoTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: white;
`;

const InfoItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const InfoContent = styled.div``;

const InfoLabel = styled.h4`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: white;
`;

const InfoText = styled.p`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.5;
  margin: 0;
`;

const ContactLink = styled.a`
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const SocialLinks = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 15px;
`;

const SocialLink = styled.a`
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const ContactForm = styled(motion.div)`
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 992px) {
    padding: 30px;
  }
`;

const FormTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: var(--color-text);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--color-text);
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputIcon = styled.span`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-text-light);
`;

const Input = styled.input`
  width: 100%;
  padding: 15px 15px 15px 45px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px rgba(93, 30, 95, 0.1);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 15px 15px 15px 45px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  font-size: 1rem;
  height: 150px;
  resize: vertical;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px rgba(93, 30, 95, 0.1);
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-secondary) 100%);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  gap: 10px;
  margin-top: 10px;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(93, 30, 95, 0.2);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const MapContainer = styled(motion.div)`
  margin-top: 70px;
  border-radius: 20px;
  overflow: hidden;
  height: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.6, ease: "easeOut" }
  }
};

const Contact = () => {
  const [formState, setFormState] = useState({
    fullname: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  
  const [formRef, formInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  
  const [mapRef, mapInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: e.target.value
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    // Simulate form submission
    setTimeout(() => {
      alert('Thank you for your message! We will get back to you soon.');
      setFormState({
        fullname: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
      setLoading(false);
    }, 1500);
    
    // In a real implementation, you would make an API call here
  };
  
  return (
    <ContactContainer>
      <Container>
        <PageHeader>
          <PageTitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, ease: "easeOut" }}
          >
            Get in <span>Touch</span>
          </PageTitle>
          <PageSubtitle
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.1, ease: "easeOut" }}
          >
            Have questions about our services or need a custom solution? We're here to help. Reach out to our team today.
          </PageSubtitle>
        </PageHeader>
        
        <ContactWrapper ref={formRef}>
          <ContactInfo
            initial="hidden"
            animate={formInView ? "visible" : "hidden"}
            variants={fadeInUp}
          >
            <InfoTitle>Contact Information</InfoTitle>
            <InfoItems>
              <InfoItem>
                <IconWrapper>
                  <FiMapPin size={24} />
                </IconWrapper>
                <InfoContent>
                  <InfoLabel>Our Location</InfoLabel>
                  <InfoText>126-Navya Techs, Time Square Empire, Mirzapar Bypass Rd, Bhuj-370001</InfoText>
                </InfoContent>
              </InfoItem>
              <InfoItem>
                <IconWrapper>
                  <FiMail size={24} />
                </IconWrapper>
                <InfoContent>
                  <InfoLabel>Email Us</InfoLabel>
                  <InfoText>
                    <ContactLink href="mailto:info@navyatechs.com">info@navyatechs.com</ContactLink><br />
                    <ContactLink href="mailto:support@navyatechs.com">support@navyatechs.com</ContactLink>
                  </InfoText>
                </InfoContent>
              </InfoItem>
              <InfoItem>
                <IconWrapper>
                  <FiPhone size={24} />
                </IconWrapper>
                <InfoContent>
                  <InfoLabel>Call Us</InfoLabel>
                  <InfoText>
                    <ContactLink href="tel:+919574001157">+91 95740 01157</ContactLink><br />
                    <ContactLink href="tel:+917861986275">+91 78619 86275</ContactLink>
                  </InfoText>
                </InfoContent>
              </InfoItem>
              <InfoItem>
                <IconWrapper>
                  <FiClock size={24} />
                </IconWrapper>
                <InfoContent>
                  <InfoLabel>Working Hours</InfoLabel>
                  <InfoText>Monday - Friday: 9:00 AM - 6:00 PM</InfoText>
                </InfoContent>
              </InfoItem>
            </InfoItems>
            <SocialLinks>
              <SocialLink href="https://www.facebook.com/people/Navya-Techs/pfbid0SdShqJUWo49hjamdLHi5Bq1UH33A8UY1tVveLc46Hk1RVPNZCbS4U7gFtCmgGDXAl/" target="_blank" rel="noopener noreferrer">
                <FiFacebook size={18} />
              </SocialLink>
              <SocialLink href="https://twitter.com/navyatechs" target="_blank" rel="noopener noreferrer">
                <FiTwitter size={18} />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/in/navya-tech/" target="_blank" rel="noopener noreferrer">
                <FiLinkedin size={18} />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/navyatechs/" target="_blank" rel="noopener noreferrer">
                <FiInstagram size={18} />
              </SocialLink>
            </SocialLinks>
          </ContactInfo>
          
          <ContactForm
            initial="hidden"
            animate={formInView ? "visible" : "hidden"}
            variants={{
              hidden: { opacity: 0, y: 30 },
              visible: { 
                opacity: 1, 
                y: 0,
                transition: { 
                  duration: 0.6, 
                  delay: 0.2, 
                  ease: "easeOut" 
                }
              }
            }}
          >
            <FormTitle>Send Us a Message</FormTitle>
            <Form onSubmit={handleSubmit}>
              <FormRow>
                <FormGroup>
                  <FormLabel htmlFor="fullname">Full Name</FormLabel>
                  <InputWrapper>
                    <InputIcon>
                      <FiUser size={18} />
                    </InputIcon>
                    <Input
                      type="text"
                      id="fullname"
                      value={formState.fullname}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      required
                    />
                  </InputWrapper>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="email">Email Address</FormLabel>
                  <InputWrapper>
                    <InputIcon>
                      <FiMail size={18} />
                    </InputIcon>
                    <Input
                      type="email"
                      id="email"
                      value={formState.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </InputWrapper>
                </FormGroup>
              </FormRow>
              <FormRow>
                <FormGroup>
                  <FormLabel htmlFor="phone">Phone Number</FormLabel>
                  <InputWrapper>
                    <InputIcon>
                      <FiPhone size={18} />
                    </InputIcon>
                    <Input
                      type="tel"
                      id="phone"
                      value={formState.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                    />
                  </InputWrapper>
                </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor="subject">Subject</FormLabel>
                  <InputWrapper>
                    <InputIcon>
                      <FiHeadphones size={18} />
                    </InputIcon>
                    <Input
                      type="text"
                      id="subject"
                      value={formState.subject}
                      onChange={handleChange}
                      placeholder="What is this regarding?"
                      required
                    />
                  </InputWrapper>
                </FormGroup>
              </FormRow>
              <FormGroup>
                <FormLabel htmlFor="message">Message</FormLabel>
                <InputWrapper>
                  <InputIcon style={{ top: '25px' }}>
                    <FiMessageSquare size={18} />
                  </InputIcon>
                  <TextArea
                    id="message"
                    value={formState.message}
                    onChange={handleChange}
                    placeholder="Type your message here..."
                    required
                  />
                </InputWrapper>
              </FormGroup>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? 'Sending...' : 'Send Message'}
                <FiSend size={18} />
              </SubmitButton>
            </Form>
          </ContactForm>
        </ContactWrapper>
        
        <MapContainer
          ref={mapRef}
          initial="hidden"
          animate={mapInView ? "visible" : "hidden"}
          variants={fadeInUp}
        >
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29493.81517642458!2d69.65042287910155!3d23.241680899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950e209000b6f7d%3A0x7077f358af0774a6!2sTime%20Square%20Empire!5e0!3m2!1sen!2sin!4v1716294348041!5m2!1sen!2sin" 
            width="600" 
            height="450" 
            style={{ border: 0 }} 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
          />
        </MapContainer>
      </Container>
    </ContactContainer>
  );
};

export default Contact;

 