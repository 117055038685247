import React from 'react';
import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 90%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 15px;
`;

const Container = ({ children, ...rest }) => {
  return <ContainerWrapper {...rest}>{children}</ContainerWrapper>;
};

export default Container; 