import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';

const NavContainer = styled.nav`
  height: 80px;
  background: ${({ scrollNav }) => (scrollNav ? 'white' : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1), transparent)')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: all 0.5s ease;
  box-shadow: ${({ scrollNav }) => (scrollNav ? '0 4px 20px rgba(0, 0, 0, 0.1)' : 'none')};
  backdrop-filter: ${({ scrollNav }) => (scrollNav ? 'blur(10px)' : 'blur(5px)')};

  @media screen and (max-width: 960px) {
    transition: all 0.8s ease;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: var(--max-width);
`;

const NavLogo = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ scrollNav }) => (scrollNav ? 'var(--color-text)' : 'white')};
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  z-index: 5;

  img {
    height: 40px;
    margin-right: 10px;
    filter: ${({ scrollNav }) => (scrollNav ? 'none' : 'drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3))')};
  }
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ scrollNav }) => (scrollNav ? 'var(--color-text)' : 'white')};
  text-shadow: none;
`;

const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: ${({ scrollNav }) => (scrollNav ? 'var(--color-text)' : 'white')};
    text-shadow: none;
  }
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  gap: 0.5rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  height: 80px;
  display: flex;
  align-items: center;
  position: relative;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 220px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;
  z-index: 10;
  overflow: hidden;
  
  ${NavItem}:hover & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

const DropdownItem = styled(Link)`
  display: block;
  padding: 12px 16px;
  color: var(--color-text);
  text-decoration: none;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
  
  &:hover {
    background: rgba(93, 30, 95, 0.05);
    color: var(--color-primary);
    border-left: 3px solid var(--color-primary);
  }
`;

// Add a function to handle dropdown item clicks with scroll-to-top behavior
const DropdownItemWithScroll = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  };

  return (
    <DropdownItem to={to} onClick={handleClick} {...rest}>
      {children}
    </DropdownItem>
  );
};

// Add a function to handle NavRouterLink clicks with scroll-to-top behavior
const NavRouterLinkWithScroll = ({ to, children, scrollNav, ...rest }) => {
  const handleClick = (e) => {
    // Handle hash links differently
    if (to.includes('#')) {
      e.preventDefault();
      const hash = to.split('#')[1];
      
      // If we're on the homepage, just scroll to the element
      if (window.location.pathname === '/' || to.startsWith('/#')) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // If we're on another page, first navigate to home
        window.location.href = `/#${hash}`;
      }
    } else {
      // For non-hash links, scroll to top
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
  };

  return (
    <NavRouterLink to={to} onClick={handleClick} scrollNav={scrollNav} {...rest}>
      {children}
    </NavRouterLink>
  );
};

const NavLinks = styled(ScrollLink)`
  color: ${({ scrollNav }) => (scrollNav ? 'var(--color-text)' : 'var(--color-primary-light)')};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  position: relative;
  font-weight: ${({ scrollNav }) => (scrollNav ? '500' : '600')};
  letter-spacing: ${({ scrollNav }) => (scrollNav ? 'normal' : '0.5px')};
  transition: color 0.3s ease;
  text-shadow: none;

  &.active {
    color: var(--color-primary);
  }

  &:hover {
    color: ${({ scrollNav }) => (scrollNav ? 'var(--color-primary)' : 'var(--color-primary)')};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 25px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: var(--color-primary);
    transition: width 0.3s ease, left 0.3s ease;
  }

  &:hover::after,
  &.active::after {
    width: 100%;
    left: 0;
  }
`;

const NavRouterLink = styled(Link)`
  color: ${({ scrollNav }) => (scrollNav ? 'var(--color-text)' : 'var(--color-primary-light)')};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  position: relative;
  font-weight: ${({ scrollNav }) => (scrollNav ? '500' : '600')};
  letter-spacing: ${({ scrollNav }) => (scrollNav ? 'normal' : '0.5px')};
  transition: color 0.3s ease;
  text-shadow: none;

  &:hover {
    color: ${({ scrollNav }) => (scrollNav ? 'var(--color-primary)' : 'var(--color-primary)')};
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 25px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: var(--color-primary);
    transition: width 0.3s ease, left 0.3s ease;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
  
  &.has-dropdown {
    padding-right: 1.5rem;
  }
  
  &.has-dropdown::before {
    content: '▾';
    font-size: 0.8rem;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const NavBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  border-radius: 50px;
  white-space: nowrap;
  padding: ${({ big }) => (big ? '14px 48px' : '10px 24px')};
  color: ${({ dark, scrollNav }) => (dark ? '#010606' : (scrollNav ? 'var(--color-primary)' : '#fff'))};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  font-weight: 600;

  &.primary {
    background: var(--color-primary);
    color: white;
    
    &:hover {
      background: var(--color-primary-light);
    }
  }
  
  &.outline {
    background: transparent;
    color: ${({ scrollNav }) => (scrollNav ? 'var(--color-primary)' : 'white')};
    border: 2px solid ${({ scrollNav }) => (scrollNav ? 'var(--color-primary)' : 'rgba(255, 255, 255, 0.3)')};
    
    &:hover {
      background: ${({ scrollNav }) => (scrollNav ? 'rgba(93, 30, 95, 0.1)' : 'rgba(255, 255, 255, 0.1)')};
    }
  }
`;

const MobileMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ scrollNav }) => (scrollNav ? 'white' : '#0b1327')};
  z-index: 99;
  overflow: hidden;
  padding: 2rem;
`;

const MobileNavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;
`;

const MobileNavItem = styled.li`
  width: 100%;
  text-align: center;
`;

const MobileNavLinks = styled(ScrollLink)`
  color: ${({ scrollNav }) => (scrollNav ? 'var(--color-text)' : 'var(--color-primary-light)')};
  display: inline-block;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
  text-shadow: none;

  &:hover,
  &.active {
    color: var(--color-primary);
  }
`;

const MobileNavBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
`;

const mobileMenuVariants = {
  closed: {
    height: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  },
  open: {
    height: "calc(100vh - 80px)",
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
      delayChildren: 0.2
    }
  }
};

const mobileItemVariants = {
  closed: {
    opacity: 0,
    y: 20,
    transition: {
      y: { stiffness: 1000 }
    }
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  }
};

const MobileNavLogo = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ scrollNav }) => (scrollNav ? 'var(--color-text)' : 'white')};
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;

  img {
    height: 40px;
    margin-right: 10px;
  }
`;

const MobileSubMenu = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  margin-top: 0.5rem;
  background: ${({ scrollNav }) => (scrollNav ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.08)')};
  border-radius: 8px;
  padding: 0.5rem 0;
`;

const MobileSubMenuItem = styled(Link)`
  display: block;
  color: ${({ scrollNav }) => (scrollNav ? 'var(--color-text)' : 'var(--color-primary-light)')};
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  text-decoration: none;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
  
  &:hover {
    color: var(--color-primary);
    background: ${({ scrollNav }) => (scrollNav ? 'rgba(0, 0, 0, 0.03)' : 'rgba(255, 255, 255, 0.05)')};
    border-left: 3px solid var(--color-primary);
  }
`;

// Add a function to handle mobile submenu item clicks with scroll-to-top behavior
const MobileSubMenuItemWithScroll = ({ to, children, onClick, ...rest }) => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    if (onClick) onClick();
  };

  return (
    <MobileSubMenuItem to={to} onClick={handleClick} {...rest}>
      {children}
    </MobileSubMenuItem>
  );
};

// Add a mobile nav link with scroll behavior
const MobileNavLinkWithScroll = ({ to, children, onClick, scrollNav, variants, style, ...rest }) => {
  const handleClick = (e) => {
    // Handle hash links differently
    if (to && typeof to === 'string' && to.includes('#')) {
      e.preventDefault();
      const hash = to.split('#')[1];
      
      // Close the mobile menu first
      if (onClick) onClick();
      
      // If we're on the homepage, just scroll to the element
      if (window.location.pathname === '/' || to.startsWith('/#')) {
        setTimeout(() => {
          const element = document.getElementById(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300); // Add delay to allow mobile menu to close
      } else {
        // If we're on another page, navigate to home with hash
        window.location.href = `/#${hash}`;
      }
    } else if (to && typeof to === 'string' && !to.startsWith('#')) {
      // For non-hash links, scroll to top
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
      // Also call the original onClick
      if (onClick) onClick();
    } else {
      // For other cases, just call the original onClick
      if (onClick) onClick();
    }
  };

  return (
    <MobileNavLinks 
      as={Link}
      to={to} 
      onClick={handleClick} 
      scrollNav={scrollNav}
      variants={variants}
      style={style}
      {...rest}
    >
      {children}
    </MobileNavLinks>
  );
};

// Add a styled button with scroll-to-top behavior
const StyledButtonWithScroll = ({ to, children, scrollNav, className, ...rest }) => {
  const handleClick = () => {
    // Only scroll to top for page navigation, not for hash links
    if (to && !to.startsWith('#')) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
  };

  return (
    <StyledButton 
      as={Link} 
      to={to} 
      onClick={handleClick} 
      scrollNav={scrollNav} 
      className={className}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

const Navbar = () => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showDevices, setShowDevices] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  return (
    <>
      <NavContainer scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" scrollNav={scrollNav}>
            <img src="/navyalogo.png" alt="Navya Techs Logo" />
          </NavLogo>
          <MobileIcon onClick={toggleMenu} scrollNav={scrollNav}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavRouterLinkWithScroll to="/" scrollNav={scrollNav}>
                Home
              </NavRouterLinkWithScroll>
            </NavItem>
            <NavItem>
              <NavRouterLinkWithScroll to="/why-navyatechs" scrollNav={scrollNav}>
                Why Navyatechs?
              </NavRouterLinkWithScroll>
            </NavItem>
            <NavItem>
              <NavRouterLinkWithScroll to="#" scrollNav={scrollNav} className="has-dropdown">
                Supported Devices
              </NavRouterLinkWithScroll>
              <DropdownMenu>
                <DropdownItemWithScroll to="/devices/transmitter">Transmitter</DropdownItemWithScroll>
                <DropdownItemWithScroll to="/devices/edfa">EDFA</DropdownItemWithScroll>
                <DropdownItemWithScroll to="/devices/pon-edfa">PON EDFA</DropdownItemWithScroll>
                <DropdownItemWithScroll to="/devices/gpon">GPON</DropdownItemWithScroll>
                <DropdownItemWithScroll to="/devices/gepon">GEPON</DropdownItemWithScroll>
              </DropdownMenu>
            </NavItem>
            <NavItem>
              <NavRouterLinkWithScroll to="/#pricing" scrollNav={scrollNav}>
                Pricing
              </NavRouterLinkWithScroll>
            </NavItem>
            <NavItem>
              <NavRouterLinkWithScroll to="/contact" scrollNav={scrollNav}>
                Contact
              </NavRouterLinkWithScroll>
            </NavItem>
          </NavMenu>
          <NavBtnWrapper>
            <StyledButtonWithScroll 
              className="outline" 
              to="http://localhost:3000/login"
              scrollNav={scrollNav}
            >
              Sign In
            </StyledButtonWithScroll>
            <StyledButtonWithScroll 
              className="primary" 
              to="http://localhost:3000/register"
              scrollNav={scrollNav}
            >
              Get Started
            </StyledButtonWithScroll>
          </NavBtnWrapper>
        </NavbarContainer>
      </NavContainer>

      <AnimatePresence>
        {isOpen && (
          <MobileMenu
            variants={mobileMenuVariants}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            transition={{ duration: 0.5 }}
            scrollNav={scrollNav}
          >
            <MobileNavMenu>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="/"
                  variants={mobileItemVariants}
                  onClick={closeMenu}
                  scrollNav={scrollNav}
                >
                  Home
                </MobileNavLinkWithScroll>
              </MobileNavItem>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="/why-navyatechs"
                  variants={mobileItemVariants}
                  onClick={closeMenu}
                  scrollNav={scrollNav}
                >
                  Why Navyatechs?
                </MobileNavLinkWithScroll>
              </MobileNavItem>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="#"
                  onClick={() => setShowDevices(!showDevices)}
                  scrollNav={scrollNav}
                  variants={mobileItemVariants}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  Supported Devices
                  <span style={{ marginLeft: '5px', fontSize: '0.8rem' }}>
                    {showDevices ? '▴' : '▾'}
                  </span>
                </MobileNavLinkWithScroll>
                
                <AnimatePresence>
                  {showDevices && (
                    <MobileSubMenu
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <MobileSubMenuItemWithScroll 
                        to="/devices/transmitter" 
                        onClick={closeMenu}
                        scrollNav={scrollNav}
                      >
                        Transmitter
                      </MobileSubMenuItemWithScroll>
                      <MobileSubMenuItemWithScroll 
                        to="/devices/edfa" 
                        onClick={closeMenu}
                        scrollNav={scrollNav}
                      >
                        EDFA
                      </MobileSubMenuItemWithScroll>
                      <MobileSubMenuItemWithScroll 
                        to="/devices/pon-edfa" 
                        onClick={closeMenu}
                        scrollNav={scrollNav}
                      >
                        PON EDFA
                      </MobileSubMenuItemWithScroll>
                      <MobileSubMenuItemWithScroll 
                        to="/devices/gpon" 
                        onClick={closeMenu}
                        scrollNav={scrollNav}
                      >
                        GPON
                      </MobileSubMenuItemWithScroll>
                      <MobileSubMenuItemWithScroll 
                        to="/devices/gepon" 
                        onClick={closeMenu}
                        scrollNav={scrollNav}
                      >
                        GEPON
                      </MobileSubMenuItemWithScroll>
                    </MobileSubMenu>
                  )}
                </AnimatePresence>
              </MobileNavItem>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="solutions"
                  variants={mobileItemVariants}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  onClick={closeMenu}
                  scrollNav={scrollNav}
                >
                  Solutions
                </MobileNavLinkWithScroll>
              </MobileNavItem>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="network-devices"
                  variants={mobileItemVariants}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  onClick={closeMenu}
                  scrollNav={scrollNav}
                >
                  Network Devices
                </MobileNavLinkWithScroll>
              </MobileNavItem>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="/#pricing"
                  variants={mobileItemVariants}
                  onClick={closeMenu}
                  scrollNav={scrollNav}
                >
                  Pricing
                </MobileNavLinkWithScroll>
              </MobileNavItem>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="team"
                  variants={mobileItemVariants}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  onClick={closeMenu}
                  scrollNav={scrollNav}
                >
                  Team
                </MobileNavLinkWithScroll>
              </MobileNavItem>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="testimonials"
                  variants={mobileItemVariants}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  onClick={closeMenu}
                  scrollNav={scrollNav}
                >
                  Testimonials
                </MobileNavLinkWithScroll>
              </MobileNavItem>
              <MobileNavItem>
                <MobileNavLinkWithScroll
                  to="/contact"
                  variants={mobileItemVariants}
                  onClick={closeMenu}
                  scrollNav={scrollNav}
                >
                  Contact
                </MobileNavLinkWithScroll>
              </MobileNavItem>
            </MobileNavMenu>
            <MobileNavBtnWrapper>
              <StyledButtonWithScroll
                className="outline"
                to="http://localhost:3000/login"
                style={{ width: '100%' }}
                onClick={closeMenu}
                scrollNav={scrollNav}
              >
                Sign in
              </StyledButtonWithScroll>
              <StyledButtonWithScroll
                className="primary"
                to="http://localhost:3000/register"
                style={{ width: '100%' }}
                onClick={closeMenu}
                scrollNav={scrollNav}
              >
                Get Started
              </StyledButtonWithScroll>
            </MobileNavBtnWrapper>
          </MobileMenu>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar; 