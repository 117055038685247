import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('ErrorBoundary caught an error:', error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ 
          padding: '20px', 
          margin: '20px',
          backgroundColor: '#fff3f3', 
          border: '1px solid #ffcccb', 
          borderRadius: '8px' 
        }}>
          <h2 style={{ color: '#d32f2f' }}>
            {this.props.componentName || 'Component'} Failed to Load
          </h2>
          <p>
            There was an error loading this component. 
            Try refreshing the page or contact support if the issue persists.
          </p>
          <details style={{ 
            marginTop: '15px', 
            padding: '10px', 
            backgroundColor: '#f5f5f5', 
            borderRadius: '4px' 
          }}>
            <summary>Error Details (for developers)</summary>
            <pre style={{ overflow: 'auto', maxHeight: '200px' }}>
              {this.state.error && this.state.error.toString()}
            </pre>
            <pre style={{ overflow: 'auto', maxHeight: '300px' }}>
              {this.state.errorInfo && this.state.errorInfo.componentStack}
            </pre>
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 