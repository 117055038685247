import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import Container from '../components/Container';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const SectionContainer = styled.section`
  padding: 100px 0;
  background: #f8fbff;
  position: relative;
  overflow: hidden;
`;

const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(rgba(0, 0, 0, 0.03) 1px, transparent 1px);
  background-size: 25px 25px;
  opacity: 0.8;
`;

const SectionHeader = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px;
`;

const HeaderBadge = styled(motion.div)`
  background-color: rgba(93, 30, 95, 0.1);
  color: var(--color-primary);
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  
  svg {
    margin-right: 8px;
  }
`;

const SectionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-text);
  
  span {
    color: var(--color-primary);
  }
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.1rem;
  color: var(--color-text-light);
  line-height: 1.7;
  margin-bottom: 30px;
`;

const CarouselContainer = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
`;

const DeviceCarousel = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const DeviceItem = styled(motion.div)`
  width: 100%;
  border-radius: 24px;
  margin-bottom: 5px;
  position: relative;
  background-color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: auto;
  min-height: 400px;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.12);

    .view-details-button {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
`;

const DeviceImageWrapper = styled.div`
  width: 100%;
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
  position: relative;
  min-height: 300px;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  }

  img {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }
`;

const DeviceImageFrame = styled(motion.div)`
  background: white;
  padding: 10px;
  border-radius: 1px;
  margin: 0 auto;
  display: inline-flex;
  max-width: 90%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  will-change: transform, opacity;
`;

const DeviceInfo = styled(motion.div)`
  padding: 30px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DeviceName = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 12px;
  color: var(--color-text);
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: linear-gradient(90deg, #6978ff, var(--color-primary));
    border-radius: 2px;
  }
`;

const DeviceFullName = styled.h4`
  font-size: 1.1rem;
  color: #6978ff;
  margin-bottom: 15px;
  font-weight: 500;
`;

const DeviceDescription = styled.p`
  font-size: 1.05rem;
  color: var(--color-text-light);
  line-height: 1.7;
`;

const NavigationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
`;

const NavButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--color-primary);
  font-size: 1.5rem;
  
  &:hover {
    background: var(--color-primary);
    color: white;
    transform: translateY(-2px);
  }
  
  &:disabled {
    background: #e9ecef;
    color: #adb5bd;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const DeviceNumberIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--color-text-light);
  padding: 0 20px;
`;

const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--color-primary);
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ViewDetailsButton = styled(motion.div)`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  background: linear-gradient(135deg, #0c2340 0%, #1e5799 100%);
  color: white;
  padding: 12px 24px;
  border-radius: 50px;
  font-weight: 600;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 5;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`;

const deviceData = [
  {
    id: 1,
    name: "Transmitter",
    description: "Optical transmitters for fiber networks that convert electrical signals to optical signals",
    image: "/assets/devices/transimg.png",
    fullName: "Optical Transmitter",
    route: "/devices/transmitter"
  },
  {
    id: 2,
    name: "EDFA",
    description: "Erbium Doped Fiber Amplifier for boosting optical signals over long distances. These devices are essential for maintaining signal integrity in FTTH networks.",
    image: "/assets/devices/edfaimg.png",
    fullName: "Erbium Doped Fiber Amplifier",
    route: "/devices/edfa"
  },
  {
    id: 3,
    name: "PON-EDFA",
    description: "Passive Optical Network amplifiers that enhance signal strength in PON architectures",
    image: "/assets/devices/pon-edfaimg.png",
    fullName: "Passive Optical Network - Erbium Doped Fiber Amplifier",
    route: "/devices/pon-edfa"
  },
  {
    id: 4,
    name: "GPON-OLT",
    description: "Gigabit Passive Optical Network terminals for high-speed fiber communications",
    image: "/assets/devices/gpoimg.png",
    fullName: "Gigabit Passive Optical Network - Optical Line Terminal",
    route: "/devices/gpon"
  },
  {
    id: 5,
    name: "EPON-OLT",
    description: "Ethernet Passive Optical Network terminals for cost-effective fiber deployment",
    image: "/assets/devices/eponimg.png",
    fullName: "Ethernet Passive Optical Network - Optical Line Terminal",
    route: "/devices/gepon"
  }
];

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const imageVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, ease: "easeOut" }
  }
};

const textVariants = {
  hidden: { opacity: 0, y: 15 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, ease: "easeOut" }
  }
};

const deviceContentVariants = {
  hidden: { 
    opacity: 0,
    scale: 0.98
  },
  visible: { 
    opacity: 1,
    scale: 1,
    transition: { 
      duration: 0.5,
      ease: "easeOut",
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  },
  exit: { 
    opacity: 0,
    scale: 0.98,
    transition: { 
      duration: 0.3,
      ease: "easeIn",
      when: "afterChildren",
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  }
};

const NetworkDevicesSection = () => {
  const [currentDevice, setCurrentDevice] = useState(0);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const navigate = useNavigate();
  
  // Preload device images
  useEffect(() => {
    const preloadImages = () => {
      const imagePromises = deviceData.map(device => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = device.image;
          img.onload = resolve;
          img.onerror = resolve; // Still resolve on error to continue
        });
      });
      
      Promise.all(imagePromises)
        .then(() => setImagesLoaded(true))
        .catch(() => setImagesLoaded(true)); // Set loaded even if there are errors
    };
    
    preloadImages();
  }, []);
  
  const nextDevice = () => {
    setCurrentDevice(prev => (prev === deviceData.length - 1 ? 0 : prev + 1));
  };
  
  const prevDevice = () => {
    setCurrentDevice(prev => (prev === 0 ? deviceData.length - 1 : prev - 1));
  };
  
  // Create a default fallback image URL
  const fallbackImageUrl = "/assets/devices/default-device.png";

  const handleDeviceClick = () => {
    // Scroll to top before navigating
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    navigate(deviceData[currentDevice].route);
  };

  return (
    <SectionContainer id="network-devices" ref={ref}>
      <BackgroundPattern />
      <Container>
        <SectionHeader>
          <HeaderBadge
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 3H5C3.89543 3 3 3.89543 3 5V9C3 10.1046 3.89543 11 5 11H9C10.1046 11 11 10.1046 11 9V5C11 3.89543 10.1046 3 9 3Z" fill="currentColor" />
              <path d="M19 3H15C13.8954 3 13 3.89543 13 5V9C13 10.1046 13.8954 11 15 11H19C20.1046 11 21 10.1046 21 9V5C21 3.89543 20.1046 3 19 3Z" fill="currentColor" />
              <path d="M9 13H5C3.89543 13 3 13.8954 3 15V19C3 20.1046 3.89543 21 5 21H9C10.1046 21 11 20.1046 11 19V15C11 13.8954 10.1046 13 9 13Z" fill="currentColor" />
              <path d="M19 13H15C13.8954 13 13 13.8954 13 15V19C13 20.1046 13.8954 21 15 21H19C20.1046 21 21 20.1046 21 19V15C21 13.8954 20.1046 13 19 13Z" fill="currentColor" />
            </svg>
            Network Devices
          </HeaderBadge>
          <SectionTitle
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Design Your <span>Fiber Network</span> With Our Supported Devices
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.7, delay: 0.3 }}
          >
            Our platform supports a comprehensive range of optical network devices, allowing you to design, optimize, and monitor complex fiber networks with ease.
          </SectionSubtitle>
        </SectionHeader>
        
        <CarouselContainer>
          <DeviceCarousel>
            {imagesLoaded && (
              <AnimatePresence mode="wait">
                <DeviceItem
                  key={currentDevice}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={deviceContentVariants}
                  layout
                  onClick={handleDeviceClick}
                >
                  <DeviceImageWrapper>
                    <DeviceImageFrame
                      as={motion.div}
                      variants={imageVariants}
                    >
                      <img 
                        src={deviceData[currentDevice].image} 
                        alt={deviceData[currentDevice].name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = fallbackImageUrl;
                        }}
                      />
                    </DeviceImageFrame>
                  </DeviceImageWrapper>
                  <DeviceInfo variants={staggerContainer}>
                    <DeviceName as={motion.h3} variants={textVariants}>
                      {deviceData[currentDevice].name}
                    </DeviceName>
                    <DeviceFullName as={motion.h4} variants={textVariants}>
                      {deviceData[currentDevice].fullName}
                    </DeviceFullName>
                    <DeviceDescription as={motion.p} variants={textVariants}>
                      {deviceData[currentDevice].description}
                    </DeviceDescription>
                  </DeviceInfo>
                  
                  <ViewDetailsButton className="view-details-button">
                    View Details
                  </ViewDetailsButton>
                </DeviceItem>
              </AnimatePresence>
            )}

            {!imagesLoaded && (
              <div style={{ height: "600px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <LoadingSpinner />
              </div>
            )}

            <NavigationButtons>
              <NavButton onClick={prevDevice} aria-label="Previous device">
                <FiChevronLeft />
              </NavButton>
              <DeviceNumberIndicator>
                {currentDevice + 1} / {deviceData.length}
              </DeviceNumberIndicator>
              <NavButton onClick={nextDevice} aria-label="Next device">
                <FiChevronRight />
              </NavButton>
            </NavigationButtons>
          </DeviceCarousel>
        </CarouselContainer>
      </Container>
    </SectionContainer>
  );
};

export default NetworkDevicesSection; 