import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Container from '../components/Container';
import { FiAward, FiUsers, FiMapPin, FiGlobe, FiCalendar, FiTarget } from 'react-icons/fi';

const SectionContainer = styled.section`
  padding: 100px 0;
  background: linear-gradient(to bottom, #fff, #f8f9fa);
  position: relative;
`;

const HeaderBadge = styled(motion.div)`
  background-color: rgba(93, 30, 95, 0.1);
  color: var(--color-primary);
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  
  svg {
    margin-right: 8px;
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-text);
  
  span {
    color: var(--color-primary);
  }
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.1rem;
  color: var(--color-text-light);
  line-height: 1.7;
  margin-bottom: 30px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const ContentLeft = styled(motion.div)`
  @media (max-width: 992px) {
    order: 2;
  }
`;

const ContentRight = styled(motion.div)`
  @media (max-width: 992px) {
    order: 1;
  }
`;

const StoryText = styled(motion.p)`
  font-size: 1.05rem;
  color: var(--color-text-light);
  line-height: 1.8;
  margin-bottom: 24px;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const StatItem = styled(motion.div)`
  text-align: center;
`;

const StatValue = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-primary);
  margin-bottom: 8px;
`;

const StatLabel = styled.div`
  font-size: 1rem;
  color: var(--color-text-light);
  font-weight: 500;
`;

const ImageMosaic = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  height: 500px;
  
  @media (max-width: 576px) {
    height: 400px;
  }
`;

const ImageWrapper = styled(motion.div)`
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100%;
  
  &:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  
  &:nth-child(2) {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
  
  &:nth-child(3) {
    grid-column: 1;
    grid-row: 2;
  }
`;

const MosaicImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
  
  ${ImageWrapper}:hover & {
    transform: scale(1.05);
  }
`;

const ValuesSection = styled.section`
  padding: 80px 0;
  background: #fff;
`;

const ValuesList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 50px;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const ValueCard = styled(motion.div)`
  background: white;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const ValueIconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background: rgba(93, 30, 95, 0.1);
  color: var(--color-primary);
  font-size: 24px;
`;

const ValueTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--color-text);
`;

const ValueDescription = styled.p`
  font-size: 1rem;
  color: var(--color-text-light);
  line-height: 1.7;
`;

const TeamSection = styled.section`
  padding: 80px 0;
  background: #f8f9fa;
`;

const TeamLeadership = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 50px;
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const TeamMember = styled(motion.div)`
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const TeamMemberImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const TeamMemberInfo = styled.div`
  padding: 20px;
  text-align: center;
`;

const MemberName = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--color-text);
`;

const MemberPosition = styled.p`
  font-size: 0.9rem;
  color: var(--color-primary);
  font-weight: 500;
  margin-bottom: 15px;
`;

const MemberBio = styled.p`
  font-size: 0.95rem;
  color: var(--color-text-light);
  line-height: 1.6;
`;

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.6, ease: "easeOut" }
  }
};

const fadeInRight = {
  hidden: { opacity: 0, x: -30 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: { duration: 0.6, ease: "easeOut" }
  }
};

const fadeInLeft = {
  hidden: { opacity: 0, x: 30 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: { duration: 0.6, ease: "easeOut" }
  }
};

const AboutUsSection = () => {
  const [storyRef, storyInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  
  const [valuesRef, valuesInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  
  const [teamRef, teamInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  
  const values = [
    {
      id: 1,
      icon: <FiTarget />,
      title: "Innovation",
      description: "We constantly explore cutting-edge technologies to enhance our network design platform, staying ahead of industry trends."
    },
    {
      id: 2,
      icon: <FiUsers />,
      title: "Customer Focus",
      description: "We place our customers at the center of everything we do, creating solutions that address their unique challenges."
    },
    {
      id: 3,
      icon: <FiAward />,
      title: "Excellence",
      description: "We strive for excellence in all aspects of our work, from product development to customer service and support."
    }
  ];
  
  const teamMembers = [
    {
      id: 1,
      name: "Rajesh Kumar",
      position: "Founder & CEO",
      bio: "With over 25 years of experience in telecom infrastructure, Rajesh founded Navya Techs to revolutionize FTTH network design and management.",
      image: "/team/team-1.jpg"
    },
    {
      id: 2,
      name: "Priya Sharma",
      position: "CTO",
      bio: "Priya leads our technology initiatives with her expertise in fiber optics and network architecture, driving innovation across our platform.",
      image: "/team/team-2.jpg"
    },
    {
      id: 3,
      name: "Vikram Singh",
      position: "Head of Product",
      bio: "Vikram ensures our solutions are intuitive, powerful, and aligned with the evolving needs of telecom operators worldwide.",
      image: "/team/team-3.jpg"
    }
  ];
  
  return (
    <>
      <SectionContainer id="about-us" ref={storyRef}>
        <Container>
          <SectionHeader>
            <HeaderBadge
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FiUsers size={16} />
              About Us
            </HeaderBadge>
            <SectionTitle
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.2 }}
            >
              Our <span>Journey</span> and Mission
            </SectionTitle>
            <SectionSubtitle
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.3 }}
            >
              Pioneering the future of FTTH network design and management since 2005
            </SectionSubtitle>
          </SectionHeader>
          
          <Grid>
            <ContentLeft
              variants={fadeInRight}
              initial="hidden"
              animate={storyInView ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <StoryText>
                Founded in 2005, Navya Techs began with a simple yet powerful vision: to transform how telecom operators design and maintain their fiber networks. What started as a small team of passionate engineers has grown into a global leader in FTTH network management solutions.
              </StoryText>
              <StoryText>
                Our journey has been marked by continuous innovation and a deep understanding of the challenges faced by network operators across the world. We've witnessed the evolution of fiber technologies and have consistently stayed ahead of the curve, developing solutions that not only address current needs but anticipate future requirements.
              </StoryText>
              <StoryText>
                Today, Navya Techs empowers over 65 companies across 12 countries with cutting-edge tools that simplify complex network design, provide real-time monitoring, and enable predictive maintenance capabilities.
              </StoryText>
              
              <StatsGrid>
                <StatItem
                  variants={fadeInUp}
                  initial="hidden"
                  animate={storyInView ? "visible" : "hidden"}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <StatValue>19+</StatValue>
                  <StatLabel>Years of Experience</StatLabel>
                </StatItem>
                <StatItem
                  variants={fadeInUp}
                  initial="hidden"
                  animate={storyInView ? "visible" : "hidden"}
                  transition={{ duration: 0.6, delay: 0.5 }}
                >
                  <StatValue>65+</StatValue>
                  <StatLabel>Clients Worldwide</StatLabel>
                </StatItem>
                <StatItem
                  variants={fadeInUp}
                  initial="hidden"
                  animate={storyInView ? "visible" : "hidden"}
                  transition={{ duration: 0.6, delay: 0.6 }}
                >
                  <StatValue>12</StatValue>
                  <StatLabel>Countries Served</StatLabel>
                </StatItem>
              </StatsGrid>
            </ContentLeft>
            
            <ContentRight
              variants={fadeInLeft}
              initial="visible"
              animate={storyInView ? "visible" : "hidden"}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <ImageMosaic>
                <ImageWrapper>
                  <MosaicImage src="/about/office.jpg" alt="Navya Techs Office" />
                </ImageWrapper>
                <ImageWrapper>
                  <MosaicImage src="/about/team.jpg" alt="Navya Techs Team" />
                </ImageWrapper>
                <ImageWrapper>
                  <MosaicImage src="/about/product.jpg" alt="Navya Techs Product" />
                </ImageWrapper>
              </ImageMosaic>
            </ContentRight>
          </Grid>
        </Container>
      </SectionContainer>
      
      {/* <ValuesSection ref={valuesRef}>
        <Container>
          <SectionHeader>
            <HeaderBadge
              initial={{ opacity: 0, y: 20 }}
              animate={valuesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.5 }}
            >
              <FiTarget size={16} />
              Our Values
            </HeaderBadge>
            <SectionTitle
              initial={{ opacity: 0, y: 20 }}
              animate={valuesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.7, delay: 0.2 }}
            >
              Principles That <span>Guide</span> Us
            </SectionTitle>
            <SectionSubtitle
              initial={{ opacity: 0, y: 20 }}
              animate={valuesInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.7, delay: 0.3 }}
            >
              The core values that shape our culture and define how we work
            </SectionSubtitle>
          </SectionHeader>
          
          <ValuesList>
            {values.map((value, index) => (
              <ValueCard
                key={value.id}
                variants={fadeInUp}
                initial="hidden"
                animate={valuesInView ? "visible" : "hidden"}
                transition={{ duration: 0.6, delay: 0.3 + (index * 0.1) }}
              >
                <ValueIconWrapper>
                  {value.icon}
                </ValueIconWrapper>
                <ValueTitle>{value.title}</ValueTitle>
                <ValueDescription>{value.description}</ValueDescription>
              </ValueCard>
            ))}
          </ValuesList>
        </Container>
      </ValuesSection> */}
      
      {/* <TeamSection ref={teamRef}>
        <Container>
          <SectionHeader>
            <HeaderBadge
              initial={{ opacity: 0, y: 20 }}
              animate={teamInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.5 }}
            >
              <FiUsers size={16} />
              Our Team
            </HeaderBadge>
            <SectionTitle
              initial={{ opacity: 0, y: 20 }}
              animate={teamInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.7, delay: 0.2 }}
            >
              Meet Our <span>Leadership</span>
            </SectionTitle>
            <SectionSubtitle
              initial={{ opacity: 0, y: 20 }}
              animate={teamInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.7, delay: 0.3 }}
            >
              The experienced professionals behind Navya Techs' success
            </SectionSubtitle>
          </SectionHeader>
          
          <TeamLeadership>
            {teamMembers.map((member, index) => (
              <TeamMember
                key={member.id}
                variants={fadeInUp}
                initial="hidden"
                animate={teamInView ? "visible" : "hidden"}
                transition={{ duration: 0.6, delay: 0.3 + (index * 0.1) }}
              >
                <TeamMemberImage src={member.image} alt={member.name} />
                <TeamMemberInfo>
                  <MemberName>{member.name}</MemberName>
                  <MemberPosition>{member.position}</MemberPosition>
                  <MemberBio>{member.bio}</MemberBio>
                </TeamMemberInfo>
              </TeamMember>
            ))}
          </TeamLeadership>
        </Container>
      </TeamSection> */}
    </>
  );
};

export default AboutUsSection; 