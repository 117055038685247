import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiPlay } from 'react-icons/fi';
import Container from '../components/Container';

const HeroContainer = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  background: linear-gradient(135deg, #0c2340 0%, #5D1E5F 100%);
  
  @media (max-width: 768px) {
    min-height: 650px;
  }
`;

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const HeroBgPattern = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('/grid-pattern.png') repeat;
  opacity: 0.1;
`;

const HeroContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  position: relative;
  z-index: 1;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
  }
`;

const HeroLeft = styled.div`
  flex: 1;
  max-width: 600px;
  
  @media (max-width: 1024px) {
    max-width: 100%;
    order: 2;
  }
`;

const HeroRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
    order: 1;
  }
`;

const HeroHeading = styled(motion.h1)`
  margin-bottom: 1.5rem;
  line-height: 1.1;
  color: #fff;
  font-size: 3.5rem;
  
  span {
    color: var(--color-accent-dark);
  }
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HeroSubtitle = styled(motion.p)`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 540px;
  
  @media (max-width: 1024px) {
    margin: 0 auto 2rem;
  }
`;

const FeatureBox = styled(motion.div)`
  position: absolute;
  background-color: rgba(20, 30, 50, 0.85);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 2;
  border-left: 3px solid #4361ee;
  
  &.top-right {
    top: 10%;
    right: -5%;
    max-width: 280px;
  }
  
  &.bottom-left {
    bottom: 10%;
    left: -5%;
    max-width: 280px;
  }
  
  @media (max-width: 1200px) {
    &.top-right {
      top: 5%;
      right: 0;
    }
    
    &.bottom-left {
      bottom: 5%;
      left: 0;
    }
  }
  
  @media (max-width: 768px) {
    padding: 10px 15px;
    max-width: 220px;
    
    &.top-right {
      top: 0;
      right: 0;
    }
    
    &.bottom-left {
      bottom: 0;
      left: 0;
    }
  }
`;

const FeatureIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bg || 'rgba(67, 97, 238, 0.2)'};
  color: ${props => props.color || '#4361ee'};
  font-size: 18px;
  flex-shrink: 0;
  
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h4`
  color: white;
  margin: 0 0 5px;
  font-size: 1rem;
  font-weight: 600;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const FeatureText = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.4;
  
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const EarthImage = styled(motion.div)`
  width: 450px;
  height: 450px;
  position: relative;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
  }
`;

const HeroButtons = styled(motion.div)`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 1024px) {
    justify-content: center;
  }
  
  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }
`;

const StyledButton = styled(motion.button)`
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &.primary {
    background: #4361ee;
    color: white;
    border: none;
    
    &:hover {
      background: #3a56d4;
    }
  }
  
  &.secondary {
    background: transparent;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.3);
    
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;

const Stats = styled(motion.div)`
  display: flex;
  margin-top: 3rem;
  
  @media (max-width: 1024px) {
    justify-content: center;
  }
  
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
`;

const StatItem = styled.div`
  margin-right: 2.5rem;
  
  @media (max-width: 480px) {
    margin-right: 0;
  }
`;

const StatValue = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #4361ee;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.div`
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
`;

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.8, ease: "easeOut" }
  }
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3
    }
  }
};

const rotateAnimation = {
  animate: { 
    rotate: 360,
    transition: {
      duration: 180,
      repeat: Infinity,
      ease: "linear"
    }
  }
};

const HeroSection = () => {
  return (
    <HeroContainer id="hero">
      {/* <HeroBg>
        <HeroBgPattern />
      </HeroBg> */}
      <Container>
        <HeroContent>
          <HeroLeft>
            <motion.div
              variants={staggerContainer}
              initial="hidden"
              animate="visible"
            >
              <HeroHeading variants={fadeInUp}>
                Simplify Your <span>Network Design</span> with Navya Techs
              </HeroHeading>
              <HeroSubtitle variants={fadeInUp}>
                Design, manage, and optimize your cable TV and broadband networks with our easy-to-use platform. Perfect for network operators looking for a reliable solution.
              </HeroSubtitle>
              <HeroButtons variants={fadeInUp}>
                
                <StyledButton
                  className="primary"
                  as={Link} 
                  to="http://localhost:3000/register" 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Start Free Trial <FiArrowRight />
                </StyledButton>
                <StyledButton
                  className="secondary"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  as="a"
                  href="#video-demo"
                >
                  <FiPlay /> Watch Demo
                </StyledButton>
              </HeroButtons>
              
              <Stats variants={fadeInUp}>
                <StatItem>
                  <StatValue>65+</StatValue>
                  <StatLabel>Trusted Companies</StatLabel>
                </StatItem>
                <StatItem>
                  <StatValue>19+</StatValue>
                  <StatLabel>Years Experience</StatLabel>
                </StatItem>
              </Stats>
            </motion.div>
          </HeroLeft>
          <HeroRight>
            <EarthImage
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <motion.img 
                src="/network-diagram.png" 
                alt="Network Diagram"
                animate={{ 
                  rotate: 360,
                  transition: {
                    duration: 40,
                    repeat: Infinity,
                    ease: "linear"
                  }
                }}
                style={{ 
                  filter: "drop-shadow(0 0 20px rgba(67, 97, 238, 0.3))",
                  transformOrigin: "center center"
                }}
                onError={(e) => {
                  e.target.src = "https://placehold.co/600x600/011627/4361ee.png?text=Network+Diagram";
                }}
              />
              
              {/* Feature Box 1: Real-time Network Analysis */}
              <FeatureBox 
                className="top-right"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.10, duration: 0.30 }}
              >
                <FeatureIcon bg="rgba(83, 82, 237, 0.2)" color="#5352ed">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5H4C2.89543 5 2 5.89543 2 7V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V7C22 5.89543 21.1046 5 20 5H16M8 5V3C8 2.44772 8.44772 2 9 2H15C15.5523 2 16 2.44772 16 3V5M8 5H16M9 14L11 16L15 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </FeatureIcon>
                <FeatureContent>
                  <FeatureTitle>Real-time Network Analysis</FeatureTitle>
                  <FeatureText>Monitor your network performance with live data and insights</FeatureText>
                </FeatureContent>
              </FeatureBox>
              
              {/* Feature Box 2: Optimize Performance */}
              <FeatureBox 
                className="bottom-left"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.8, duration: 0.8 }}
              >
                <FeatureIcon bg="rgba(72, 219, 251, 0.2)" color="#48dbfb">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </FeatureIcon>
                <FeatureContent>
                  <FeatureTitle>Optimize Performance</FeatureTitle>
                  <FeatureText>Automatically detect and resolve network bottlenecks</FeatureText>
                </FeatureContent>
              </FeatureBox>
            </EarthImage>
          </HeroRight>
        </HeroContent>
      </Container>
    </HeroContainer>
  );
};

export default HeroSection; 