import styled, { css } from 'styled-components';

const ButtonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.size === 'large' ? '14px 28px' : props.size === 'small' ? '8px 16px' : '12px 24px'};
  border-radius: var(--border-radius);
  font-weight: 600;
  font-size: ${props => props.size === 'large' ? '1.1rem' : props.size === 'small' ? '0.9rem' : '1rem'};
  text-align: center;
  cursor: pointer;
  transition: var(--transition);
  gap: 0.5rem;
  white-space: nowrap;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  svg {
    font-size: 1.2em;
  }
`;

const PrimaryButton = styled.button`
  ${ButtonStyles}
  color: white;
  background: ${props => props.outlined 
    ? 'transparent' 
    : props.gradient 
      ? 'linear-gradient(135deg, var(--color-primary) 0%, var(--color-secondary) 100%)' 
      : 'var(--color-primary)'};
  border: 2px solid ${props => props.gradient ? 'var(--color-primary)' : 'var(--color-primary)'};
  box-shadow: ${props => props.outlined ? 'none' : 'var(--box-shadow)'};
  
  &:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: ${props => props.outlined ? 'none' : '0 6px 25px rgba(93, 30, 95, 0.3)'};
    background: ${props => props.outlined 
      ? 'rgba(93, 30, 95, 0.1)' 
      : props.gradient 
        ? 'linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-secondary-dark) 100%)' 
        : 'var(--color-primary-dark)'};
  }

  &:active:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: ${props => props.outlined ? 'none' : '0 3px 15px rgba(93, 30, 95, 0.2)'};
  }

  ${props => props.outlined && css`
    color: var(--color-primary);
    background: transparent;
    
    &:hover:not(:disabled) {
      color: white;
      background: var(--color-primary);
    }
  `}
`;

const SecondaryButton = styled.button`
  ${ButtonStyles}
  color: ${props => props.outlined ? 'var(--color-secondary)' : 'white'};
  background: ${props => props.outlined ? 'transparent' : 'var(--color-secondary)'};
  border: 2px solid var(--color-secondary);
  box-shadow: ${props => props.outlined ? 'none' : 'var(--box-shadow)'};
  
  &:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: ${props => props.outlined ? 'none' : '0 6px 25px rgba(233, 30, 99, 0.3)'};
    background: ${props => props.outlined ? 'rgba(233, 30, 99, 0.1)' : 'var(--color-secondary-dark)'};
  }

  &:active:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: ${props => props.outlined ? 'none' : '0 3px 15px rgba(233, 30, 99, 0.2)'};
  }

  ${props => props.outlined && css`
    color: var(--color-secondary);
    background: transparent;
    
    &:hover:not(:disabled) {
      color: white;
      background: var(--color-secondary);
    }
  `}
`;

const TextButton = styled.button`
  ${ButtonStyles}
  background: transparent;
  color: var(--color-primary);
  border: none;
  padding: 8px 16px;
  
  &:hover:not(:disabled) {
    background: rgba(93, 30, 95, 0.1);
  }

  &:active:not(:disabled) {
    background: rgba(93, 30, 95, 0.2);
  }
`;

const Button = ({ 
  children, 
  variant = 'primary', 
  outlined = false, 
  size = 'medium',
  gradient = false,
  ...props 
}) => {
  if (variant === 'secondary') {
    return <SecondaryButton outlined={outlined} size={size} {...props}>{children}</SecondaryButton>;
  } else if (variant === 'text') {
    return <TextButton size={size} {...props}>{children}</TextButton>;
  }
  
  return (
    <PrimaryButton outlined={outlined} size={size} gradient={gradient} {...props}>
      {children}
    </PrimaryButton>
  );
};

export default Button; 