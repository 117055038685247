import React from 'react';
import { FaMapMarkedAlt, FaNetworkWired, FaSitemap, FaProjectDiagram, FaRuler, FaTools } from 'react-icons/fa';
import DeviceDetailTemplate from '../../components/DeviceDetailTemplate';

const GponPage = () => {
  const deviceData = {
    deviceName: "GPON",
    fullName: "Gigabit Passive Optical Network Design Services",
    description: "Navyatechs specializes in designing high-performance FTTH networks based on GPON technology. We help you create optimized network architectures that support up to 2.5Gbps downstream and 1.25Gbps upstream, delivering the performance needed for modern residential and business demands.",
    image: "/assets/devices/gpoimg.png",
    
    specifications: {
      intro: "Our network designs incorporate industry-standard GPON specifications for building robust FTTH infrastructures:",
      items: [
        "Architecture planning for 2.5Gbps downstream and 1.25Gbps upstream",
        "Designs compliant with ITU-T G.984 standards",
        "OLT configuration optimization for various port densities",
        "Split ratio planning up to 1:128 for maximum efficiency",
        "Network reach design up to 60km logical and 20km physical distance",
        "Integration of OMCI (ONT Management and Control Interface)",
        "Class B+ and C+ optics planning for different deployment scenarios",
        "Triple-Play service architecture (Voice, Video, Data)",
        "End-to-end Quality of Service (QoS) planning",
        "Comprehensive OAM&P feature implementation",
        "Layer 2 and Layer 3 design including VLAN, QinQ, and MPLS",
        "Redundancy planning for high availability networks"
      ]
    },
    
    features: {
      intro: "Our GPON network design services offer comprehensive benefits to ensure efficient and reliable FTTH deployments:",
      items: [
        "High subscriber density planning with minimal central office footprint",
        "Bandwidth utilization optimization through TDM/TDMA techniques",
        "Security architecture with AES-128 encryption implementation",
        "Dynamic Bandwidth Allocation (DBA) strategy for optimal resource usage",
        "Comprehensive service provisioning and activation planning",
        "Multi-service architecture for residential and business applications",
        "Remote ONT management strategy for efficient operations",
        "Seamless integration design with existing network infrastructure",
        "Legacy service support through POTS and RF overlay planning",
        "Advanced traffic management and prioritization schemes",
        "IPv6 implementation for future-proof network designs",
        "Flexible ONT deployment strategies for various scenarios"
      ]
    },
    
    useCases: [
      {
        title: "Residential FTTH Design",
        description: "We create optimized network designs for residential deployments, ensuring efficient fiber utilization and consistent performance for internet, video, and voice services.",
        image: "/assets/usecases/residential.jpg"
      },
      {
        title: "Business Connectivity Planning",
        description: "Our team develops specialized network designs for business customers with advanced QoS, security features, and high reliability to meet SLA requirements.",
        image: "/assets/usecases/business-gpon.jpg"
      },
      {
        title: "Multi-Dwelling Unit Solutions",
        description: "We design efficient GPON networks for apartment buildings and condominiums that maximize connectivity while minimizing equipment and installation costs.",
        image: "/assets/usecases/mdu.jpg"
      }
    ],
    
    networkDiagram: {
      description: "Our expert team creates comprehensive GPON FTTH network designs featuring optimal OLT placement at the central office, strategic passive optical splitter locations, and efficient ONT deployment at customer premises. We use advanced planning tools to maximize efficiency and coverage.",
      image: "/assets/diagrams/gpon-network.jpg"
    },
    
    compatibleDevices: {
      intro: "Our network designs incorporate a wide range of compatible components to create cohesive GPON-based solutions:",
      items: [
        "GPON ONTs (various form factors)",
        "Optical Splitters",
        "PLC Splitters",
        "WDM Multiplexers",
        "EDFA Amplifiers",
        "PON EDFA Systems",
        "Optical Transmitters",
        "Fiber Distribution Hubs",
        "Fiber Distribution Terminals",
        "FTTH Cables and Connectivity",
        "Network Management Systems",
        "Operation Support Systems (OSS/BSS)"
      ]
    },
    
    designTools: [
      {
        name: "GPON Network Designer",
        description: "Our expert team uses comprehensive planning tools to create end-to-end GPON networks with optimized OLT placement, splitter locations, and ONT deployments.",
        icon: <FaProjectDiagram />
      },
      {
        name: "Coverage Analyzer",
        description: "We evaluate potential coverage areas based on OLT locations, split ratios, and fiber routes to maximize your network's reach and efficiency.",
        icon: <FaMapMarkedAlt />
      },
      {
        name: "Splitter Placement Optimizer",
        description: "Our tools determine optimal locations for optical splitters based on subscriber density and geographical constraints for maximum efficiency.",
        icon: <FaSitemap />
      },
      {
        name: "Power Budget Calculator",
        description: "We calculate end-to-end optical power budgets considering OLT output power, splitter losses, fiber attenuation, and ONT sensitivity to ensure reliable connectivity.",
        icon: <FaRuler />
      },
      {
        name: "Service Simulation",
        description: "Our team simulates network performance under various traffic conditions to ensure your GPON deployment meets all subscriber needs.",
        icon: <FaNetworkWired />
      },
      {
        name: "Deployment Planner",
        description: "We create detailed deployment plans for GPON networks including central office, outside plant, and subscriber premises implementations.",
        icon: <FaTools />
      }
    ]
  };

  return <DeviceDetailTemplate {...deviceData} />;
};

export default GponPage; 